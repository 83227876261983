import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** A String or an Int or Date or List union type */
  FilterValue: { input: any; output: any; }
};

export type InventoryArticle = {
  __typename?: 'Article';
  artNo?: Maybe<Scalars['String']['output']>;
  category?: Maybe<InventoryArticleCategoryType>;
  createdAt: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  internalCurrency: Scalars['String']['output'];
  internalPrice: Scalars['Float']['output'];
  inventoryInTransit: Scalars['Float']['output'];
  isInventory: Scalars['Boolean']['output'];
  length?: Maybe<Scalars['Float']['output']>;
  lowAlert: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  nameDe?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameNo?: Maybe<Scalars['String']['output']>;
  noStock?: Maybe<Scalars['DateTime']['output']>;
  orderedQuantity: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productType?: Maybe<InventoryArticleProductType>;
  purchaseCurrency: Scalars['String']['output'];
  purchasePrice: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  reservedQuantities: Array<InventoryReservedQuantity>;
  reservedQuantitySum: Scalars['Int']['output'];
  sellOnline: Scalars['Boolean']['output'];
  stockTransactions: Array<InventoryStockTransaction>;
  unit?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export enum InventoryArticleCategoryType {
  BoltsAndNuts = 'boltsAndNuts',
  BracingBracing = 'bracingBracing',
  ConnectionPole = 'connectionPole',
  Other = 'other',
  PlatformPole = 'platformPole',
  PolesForStair = 'polesForStair',
  Steps = 'steps'
}

export type InventoryArticleInputType = {
  artNo: Scalars['String']['input'];
  category?: InputMaybe<InventoryArticleCategoryType>;
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  internalCurrency: Scalars['String']['input'];
  internalPrice: Scalars['Float']['input'];
  isInventory?: InputMaybe<Scalars['Boolean']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  lowAlert: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  nameDe?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  nameNo?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  productType?: InputMaybe<InventoryArticleProductType>;
  purchaseCurrency: Scalars['String']['input'];
  purchasePrice: Scalars['Float']['input'];
  sellOnline?: InputMaybe<Scalars['Boolean']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export enum InventoryArticleProductType {
  Cages = 'Cages',
  Grating = 'Grating',
  GratingSteps = 'GratingSteps',
  Other = 'Other',
  Platforms = 'Platforms',
  ProductionMaterial = 'ProductionMaterial',
  ProductionParts = 'ProductionParts',
  ProfilePlanks = 'ProfilePlanks',
  Railings = 'Railings',
  Ramps = 'Ramps',
  Special = 'Special',
  SpiralStair = 'SpiralStair',
  StockArticle = 'StockArticle',
  StraightStair = 'StraightStair'
}

export type InventoryArticleRow = {
  __typename?: 'ArticleRow';
  article?: Maybe<InventoryArticle>;
  articleId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  purchaseOrder: InventoryPurchaseOrder;
  purchaseOrderId: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  receivedQuantity: Scalars['Float']['output'];
  stockTransactions: Array<InventoryStockTransaction>;
  textRowReceivedQuantity?: Maybe<Scalars['Float']['output']>;
  unit: Scalars['String']['output'];
};

export type InventoryArticleRowCreateInputType = {
  articleId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  purchaseOrderId: Scalars['Float']['input'];
  quantity?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryArticleRowUpdateInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  textRowReceivedQuantity?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryComment = {
  __typename?: 'Comment';
  articleId: Scalars['Float']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryCommentInputType = {
  articleId?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
};

export enum InventoryComparator {
  Equals = 'EQUALS',
  Like = 'LIKE'
}

export enum InventoryComparatorV2 {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  Gte = 'GTE',
  In = 'IN',
  Lte = 'LTE',
  NotEqual = 'NOT_EQUAL',
  StartsWith = 'STARTS_WITH'
}

export type InventoryEanCode = {
  __typename?: 'EANCode';
  article?: Maybe<InventoryArticle>;
  articleId: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type InventoryEanCodeInputType = {
  articleId?: InputMaybe<Scalars['Float']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryFileStorage = {
  __typename?: 'FileStorage';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileNameOnDisk: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type InventoryFilteringInputType = {
  comparator: InventoryComparator;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type InventoryFilteringInputTypeV2 = {
  items: Array<InventoryFilteringItemInputType>;
  operator?: InputMaybe<InventoryFilterLogicOperator>;
};

export type InventoryFilteringItemInputType = {
  comparator: InventoryComparatorV2;
  key: Scalars['String']['input'];
  value: Scalars['FilterValue']['input'];
};

export enum InventoryFilterLogicOperator {
  And = 'AND',
  Or = 'OR'
}

export type InventoryMutation = {
  __typename?: 'Mutation';
  createArticle: InventoryArticle;
  createArticleRow: InventoryArticleRow;
  createComment: InventoryComment;
  createEANCode: InventoryEanCode;
  createPurchaseOrder: InventoryPurchaseOrder;
  createReservedQuantity: InventoryReservedQuantity;
  createStockTransaction: InventoryStockTransaction;
  createStockTransactionArticleRow: InventoryStockTransaction;
  createSupplier: InventorySupplier;
  deleteArticle: Scalars['Int']['output'];
  deleteComment: Scalars['Int']['output'];
  deleteOnlinePriceList: Scalars['Int']['output'];
  fillInPendingTransition: Array<InventoryPurchaseOrder>;
  removeArticleRow: Scalars['Int']['output'];
  removeEANCode: Scalars['Int']['output'];
  removePurchaseOrder: Scalars['Int']['output'];
  removeReservedQuantity: Scalars['Int']['output'];
  removeSupplier: Scalars['Int']['output'];
  updateArticle: InventoryArticle;
  updateArticleRow: InventoryArticleRow;
  updateEANCode: InventoryEanCode;
  updateOnlinePriceList: InventoryOnlinePriceList;
  updatePurchaseOrder: InventoryPurchaseOrder;
  updatePurchaseOrderStatus: InventoryPurchaseOrder;
  updateReservedQuantity: InventoryReservedQuantity;
  updateStockTransaction: InventoryStockTransaction;
  updateSupplier: InventorySupplier;
};


export type InventoryMutationCreateArticleArgs = {
  article: InventoryArticleInputType;
};


export type InventoryMutationCreateArticleRowArgs = {
  articleRow: InventoryArticleRowCreateInputType;
};


export type InventoryMutationCreateCommentArgs = {
  comment: InventoryCommentInputType;
};


export type InventoryMutationCreateEanCodeArgs = {
  eanCode: InventoryEanCodeInputType;
};


export type InventoryMutationCreatePurchaseOrderArgs = {
  purchaseOrder: InventoryPurchaseOrderCreateInputType;
};


export type InventoryMutationCreateReservedQuantityArgs = {
  reservedQuantity: InventoryReservedQuantityCreateInputType;
};


export type InventoryMutationCreateStockTransactionArgs = {
  stockTransaction: InventoryStockTransactionCreateInputType;
};


export type InventoryMutationCreateStockTransactionArticleRowArgs = {
  articleRowId: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
  received?: InputMaybe<Scalars['DateTime']['input']>;
};


export type InventoryMutationCreateSupplierArgs = {
  supplier: InventorySupplierInputType;
};


export type InventoryMutationDeleteArticleArgs = {
  id: Scalars['Int']['input'];
};


export type InventoryMutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationDeleteOnlinePriceListArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationFillInPendingTransitionArgs = {
  artNo: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};


export type InventoryMutationRemoveArticleRowArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationRemoveEanCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type InventoryMutationRemovePurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationRemoveReservedQuantityArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationRemoveSupplierArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryMutationUpdateArticleArgs = {
  article: InventoryArticleInputType;
  id: Scalars['ID']['input'];
};


export type InventoryMutationUpdateArticleRowArgs = {
  articleRow: InventoryArticleRowUpdateInputType;
  id: Scalars['ID']['input'];
};


export type InventoryMutationUpdateEanCodeArgs = {
  eanCode: InventoryEanCodeInputType;
  id: Scalars['Int']['input'];
};


export type InventoryMutationUpdateOnlinePriceListArgs = {
  onlinePriceList: InventoryOnlinePriceListInputType;
};


export type InventoryMutationUpdatePurchaseOrderArgs = {
  id: Scalars['ID']['input'];
  purchaseOrder: InventoryPurchaseOrderInputType;
};


export type InventoryMutationUpdatePurchaseOrderStatusArgs = {
  id: Scalars['ID']['input'];
  purchaseOrder: InventoryPurchaseOrderStatusInputType;
};


export type InventoryMutationUpdateReservedQuantityArgs = {
  id: Scalars['ID']['input'];
  reservedQuantity: InventoryReservedQuantityUpdateInputType;
};


export type InventoryMutationUpdateStockTransactionArgs = {
  id: Scalars['ID']['input'];
  stockTransaction: InventoryStockTransactionUpdateInputType;
};


export type InventoryMutationUpdateSupplierArgs = {
  id: Scalars['ID']['input'];
  supplier: InventorySupplierInputType;
};

export type InventoryOnlinePriceList = {
  __typename?: 'OnlinePriceList';
  companyId: Scalars['Float']['output'];
  companyName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  stockArticleId: Scalars['Float']['output'];
};

export type InventoryOnlinePriceListInputType = {
  companyId: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  stockArticleId: Scalars['Float']['input'];
};

export type InventoryOrderingInputType = {
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryOrderingInputTypeV2 = {
  id: Scalars['String']['input'];
  sortOrder: Scalars['String']['input'];
};

export type InventoryPaginatedArticleResponse = {
  __typename?: 'PaginatedArticleResponse';
  list: Array<InventoryArticle>;
  total: Scalars['Int']['output'];
};

export type InventoryPaginatedPurchaseOrderResponse = {
  __typename?: 'PaginatedPurchaseOrderResponse';
  list: Array<InventoryPurchaseOrder>;
  total: Scalars['Int']['output'];
};

export type InventoryPaginatedStockTransactionResponse = {
  __typename?: 'PaginatedStockTransactionResponse';
  list: Array<InventoryStockTransaction>;
  total: Scalars['Int']['output'];
};

export type InventoryPaginatedSupplierResponse = {
  __typename?: 'PaginatedSupplierResponse';
  list: Array<InventorySupplier>;
  total: Scalars['Int']['output'];
};

export type InventoryPaginationInputType = {
  pageIndex: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
};

export type InventoryPurchaseOrder = {
  __typename?: 'PurchaseOrder';
  articleRows: Array<InventoryArticleRow>;
  createdAt: Scalars['DateTime']['output'];
  creator: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateOfDispatch?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modeOfDelivery?: Maybe<InventoryValueLabel>;
  pdfText?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stockTransactions: Array<InventoryStockTransaction>;
  supplier: InventorySupplier;
  supplierId: Scalars['Float']['output'];
  supplierRef?: Maybe<Scalars['String']['output']>;
  termsOfDelivery?: Maybe<InventoryValueLabel>;
  termsOfPayment?: Maybe<InventoryValueLabel>;
};

export type InventoryPurchaseOrderCreateInputType = {
  currency: Scalars['String']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type InventoryPurchaseOrderInputType = {
  currency?: InputMaybe<Scalars['String']['input']>;
  dateOfDispatch?: InputMaybe<Scalars['DateTime']['input']>;
  modeOfDelivery?: InputMaybe<Scalars['String']['input']>;
  pdfText?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  supplierRef?: InputMaybe<Scalars['String']['input']>;
  termsOfDelivery?: InputMaybe<Scalars['String']['input']>;
  termsOfPayment?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryPurchaseOrderStatusInputType = {
  status: Scalars['String']['input'];
};

export type InventoryQuery = {
  __typename?: 'Query';
  article?: Maybe<InventoryArticle>;
  articles: InventoryPaginatedArticleResponse;
  articlesV2: InventoryPaginatedArticleResponse;
  comments: Array<InventoryComment>;
  eanCode: InventoryEanCode;
  eanCodes: Array<InventoryEanCode>;
  file: InventoryFileStorage;
  fileList: Array<InventoryFileStorage>;
  modeOfDelivery: Array<InventoryValueLabel>;
  onlinePriceListForArticle: Array<InventoryOnlinePriceList>;
  /** Return the quantity of this article pending to be receive in all related PO */
  pendingTransition: Scalars['Int']['output'];
  purchaseOrder?: Maybe<InventoryPurchaseOrder>;
  purchaseOrders: InventoryPaginatedPurchaseOrderResponse;
  purchaseOrdersByArtNo: InventoryPaginatedPurchaseOrderResponse;
  purchaseOrdersByArtNoV2: InventoryPaginatedPurchaseOrderResponse;
  purchaseOrdersV2: InventoryPaginatedPurchaseOrderResponse;
  searchArticle: Array<InventoryArticle>;
  searchWebshopArticles: Array<InventoryArticle>;
  stockTransactions: InventoryPaginatedStockTransactionResponse;
  supplier: InventorySupplier;
  suppliers: InventoryPaginatedSupplierResponse;
  suppliersV2: InventoryPaginatedSupplierResponse;
  termsOfDelivery: Array<InventoryValueLabel>;
  termsOfPayments: Array<InventoryValueLabel>;
  userGroups: Array<Scalars['String']['output']>;
};


export type InventoryQueryArticleArgs = {
  artNo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type InventoryQueryArticlesArgs = {
  filtering: Array<InventoryFilteringInputType>;
  lowAlert?: InputMaybe<Scalars['Boolean']['input']>;
  ordering: InventoryOrderingInputType;
  pagination: InventoryPaginationInputType;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQueryArticlesV2Args = {
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  lowAlert?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  pagination?: InputMaybe<InventoryPaginationInputType>;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQueryCommentsArgs = {
  articleId: Scalars['ID']['input'];
};


export type InventoryQueryEanCodeArgs = {
  id: Scalars['Int']['input'];
};


export type InventoryQueryEanCodesArgs = {
  articleId?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};


export type InventoryQueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryQueryFileListArgs = {
  articleId?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderId?: InputMaybe<Scalars['Int']['input']>;
};


export type InventoryQueryOnlinePriceListForArticleArgs = {
  artNo: Scalars['String']['input'];
};


export type InventoryQueryPendingTransitionArgs = {
  artNo: Scalars['String']['input'];
};


export type InventoryQueryPurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryQueryPurchaseOrdersArgs = {
  filtering: Array<InventoryFilteringInputType>;
  ordering: InventoryOrderingInputType;
  pagination: InventoryPaginationInputType;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQueryPurchaseOrdersByArtNoArgs = {
  artNo: Scalars['String']['input'];
  filtering: Array<InventoryFilteringInputType>;
  ordering: InventoryOrderingInputType;
  pagination: InventoryPaginationInputType;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQueryPurchaseOrdersByArtNoV2Args = {
  artNo: Scalars['String']['input'];
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  pagination?: InputMaybe<InventoryPaginationInputType>;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQueryPurchaseOrdersV2Args = {
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  pagination?: InputMaybe<InventoryPaginationInputType>;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQuerySearchArticleArgs = {
  term: Scalars['String']['input'];
};


export type InventoryQuerySearchWebshopArticlesArgs = {
  term: Scalars['String']['input'];
};


export type InventoryQueryStockTransactionsArgs = {
  filtering: Array<InventoryFilteringInputType>;
  ordering: InventoryOrderingInputType;
  pagination: InventoryPaginationInputType;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQuerySupplierArgs = {
  id: Scalars['ID']['input'];
};


export type InventoryQuerySuppliersArgs = {
  filtering: Array<InventoryFilteringInputType>;
  ordering: InventoryOrderingInputType;
  pagination: InventoryPaginationInputType;
  searching?: InputMaybe<InventorySearchingInputType>;
};


export type InventoryQuerySuppliersV2Args = {
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  pagination?: InputMaybe<InventoryPaginationInputType>;
  searching?: InputMaybe<InventorySearchingInputType>;
};

export type InventoryReservedQuantity = {
  __typename?: 'ReservedQuantity';
  esbArticleRowId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  orderNumber: Scalars['Float']['output'];
  preferredDeliveryDate: Scalars['DateTime']['output'];
  quantity: Scalars['Float']['output'];
};

export type InventoryReservedQuantityCreateInputType = {
  articleId: Scalars['Int']['input'];
  esbArticleRowId: Scalars['Float']['input'];
  orderNumber: Scalars['String']['input'];
  preferredDeliveryDate: Scalars['DateTime']['input'];
  quantity: Scalars['Float']['input'];
};

export type InventoryReservedQuantityUpdateInputType = {
  preferredDeliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export type InventorySearchingInputType = {
  fields: Array<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type InventoryStockTransaction = {
  __typename?: 'StockTransaction';
  article: InventoryArticle;
  articleId: Scalars['Float']['output'];
  articleRow: InventoryArticleRow;
  createdAt: Scalars['DateTime']['output'];
  creator: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  purchaseOrder?: Maybe<InventoryPurchaseOrder>;
  purchaseOrderId?: Maybe<Scalars['Float']['output']>;
  quantity: Scalars['Float']['output'];
  transactedAt: Scalars['DateTime']['output'];
};

export type InventoryStockTransactionCreateInputType = {
  articleId: Scalars['ID']['input'];
  articleRowId?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  ebsArticleRowId?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Float']['input'];
  transactedAt: Scalars['DateTime']['input'];
};

export type InventoryStockTransactionUpdateInputType = {
  quantity: Scalars['Float']['input'];
  transactedAt: Scalars['DateTime']['input'];
};

export type InventorySupplier = {
  __typename?: 'Supplier';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  defaultModeOfDelivery?: Maybe<Scalars['String']['output']>;
  defaultSupplierRef?: Maybe<Scalars['String']['output']>;
  defaultTermsOfDelivery?: Maybe<Scalars['String']['output']>;
  defaultTermsOfPayment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organisationNumber?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  purchaseOrders: Array<InventoryPurchaseOrder>;
  street?: Maybe<Scalars['String']['output']>;
  VATNumber?: Maybe<Scalars['String']['output']>;
};

export type InventorySupplierInputType = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultModeOfDelivery?: InputMaybe<Scalars['String']['input']>;
  defaultSupplierRef?: InputMaybe<Scalars['String']['input']>;
  defaultTermsOfDelivery?: InputMaybe<Scalars['String']['input']>;
  defaultTermsOfPayment?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organisationNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  VATNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryValueLabel = {
  __typename?: 'ValueLabel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type InventoryCreateInventoryCommentMutationVariables = Exact<{
  comment: InventoryCommentInputType;
}>;


export type InventoryCreateInventoryCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', id: string } };

export type InventoryDeleteInventoryCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InventoryDeleteInventoryCommentMutation = { __typename?: 'Mutation', deleteComment: number };

export type InventoryGetInventoryCommentsQueryVariables = Exact<{
  articleId: Scalars['ID']['input'];
}>;


export type InventoryGetInventoryCommentsQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'Comment', id: string, content: string, createdAt: any, creator: string }> };

export type InventoryUpdateInventoryArticleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  article: InventoryArticleInputType;
}>;


export type InventoryUpdateInventoryArticleMutation = { __typename?: 'Mutation', updateArticle: { __typename?: 'Article', id: string } };

export type InventoryGetInventoryArticleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InventoryGetInventoryArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', artNo?: string | null, description?: string | null, height?: number | null, id: string, length?: number | null, lowAlert: number, name: string, nameEn?: string | null, nameDe?: string | null, nameNo?: string | null, price: number, productType?: InventoryArticleProductType | null, currency: string, purchasePrice: number, purchaseCurrency: string, internalPrice: number, internalCurrency: string, quantity: number, sellOnline: boolean, isInventory: boolean, unit?: string | null, weight?: number | null, width?: number | null, category?: InventoryArticleCategoryType | null, stockTransactions: Array<{ __typename?: 'StockTransaction', id: string, price?: number | null, quantity: number, currency?: string | null, description?: string | null, transactedAt: any, createdAt: any, creator: string, article: { __typename?: 'Article', id: string, artNo?: string | null }, purchaseOrder?: { __typename?: 'PurchaseOrder', id: string } | null }>, reservedQuantities: Array<{ __typename?: 'ReservedQuantity', id: string, quantity: number, esbArticleRowId: number, orderNumber: number, preferredDeliveryDate: any }> } | null };

export type InventoryArticlesV2QueryVariables = Exact<{
  pagination?: InputMaybe<InventoryPaginationInputType>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  lowAlert?: InputMaybe<Scalars['Boolean']['input']>;
  searching?: InputMaybe<InventorySearchingInputType>;
}>;


export type InventoryArticlesV2Query = { __typename?: 'Query', result: { __typename?: 'PaginatedArticleResponse', total: number, list: Array<{ __typename?: 'Article', id: string, name: string, nameEn?: string | null, nameDe?: string | null, nameNo?: string | null, artNo?: string | null, quantity: number, lowAlert: number, reservedQuantitySum: number, inventoryInTransit: number, noStock?: any | null, productType?: InventoryArticleProductType | null, category?: InventoryArticleCategoryType | null }> } };

export type InventoryStockArticlesV2QueryVariables = Exact<{
  pagination?: InputMaybe<InventoryPaginationInputType>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  searching?: InputMaybe<InventorySearchingInputType>;
}>;


export type InventoryStockArticlesV2Query = { __typename?: 'Query', result: { __typename?: 'PaginatedArticleResponse', total: number, list: Array<{ __typename?: 'Article', id: string, name: string, price: number, internalPrice: number, internalCurrency: string, quantity: number, reservedQuantitySum: number, orderedQuantity: number, currency: string, artNo?: string | null, createdAt: string }> } };

export type InventoryGetInventoryPurchaseOrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InventoryGetInventoryPurchaseOrderQuery = { __typename?: 'Query', purchaseOrder?: { __typename?: 'PurchaseOrder', id: string, creator: string, currency: string, supplierRef?: string | null, status: string, pdfText?: string | null, dateOfDispatch?: any | null, createdAt: any, termsOfDelivery?: { __typename?: 'ValueLabel', value: string, label: string } | null, modeOfDelivery?: { __typename?: 'ValueLabel', value: string, label: string } | null, termsOfPayment?: { __typename?: 'ValueLabel', value: string, label: string } | null, stockTransactions: Array<{ __typename?: 'StockTransaction', id: string, quantity: number, description?: string | null, createdAt: any, transactedAt: any, creator: string, article: { __typename?: 'Article', id: string, artNo?: string | null } }>, articleRows: Array<{ __typename?: 'ArticleRow', id: number, name: string, quantity: number, textRowReceivedQuantity?: number | null, receivedQuantity: number, price?: number | null, unit: string, article?: { __typename?: 'Article', id: string, name: string, artNo?: string | null } | null }>, supplier: { __typename: 'Supplier', id: string, name: string } } | null };

export type InventoryGetPurchaseOrdersByArtNoV2QueryVariables = Exact<{
  artNo: Scalars['String']['input'];
  pagination?: InputMaybe<InventoryPaginationInputType>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  searching?: InputMaybe<InventorySearchingInputType>;
}>;


export type InventoryGetPurchaseOrdersByArtNoV2Query = { __typename?: 'Query', result: { __typename?: 'PaginatedPurchaseOrderResponse', total: number, list: Array<{ __typename?: 'PurchaseOrder', id: string, createdAt: any, creator: string, status: string, articleRows: Array<{ __typename?: 'ArticleRow', id: number, name: string, quantity: number, stockTransactions: Array<{ __typename?: 'StockTransaction', id: string, quantity: number, price?: number | null, createdAt: any }> }>, supplier: { __typename?: 'Supplier', name: string, id: string } }> } };

export type InventoryGetSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type InventoryGetSuppliersQuery = { __typename?: 'Query', suppliers: { __typename?: 'PaginatedSupplierResponse', list: Array<{ __typename?: 'Supplier', currency?: string | null, value: string, label: string }> } };

export type InventoryGetPurchaseOrdersV2QueryVariables = Exact<{
  pagination?: InputMaybe<InventoryPaginationInputType>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  searching?: InputMaybe<InventorySearchingInputType>;
}>;


export type InventoryGetPurchaseOrdersV2Query = { __typename?: 'Query', result: { __typename?: 'PaginatedPurchaseOrderResponse', total: number, list: Array<{ __typename?: 'PurchaseOrder', id: string, createdAt: any, creator: string, status: string, dateOfDispatch?: any | null, articleRows: Array<{ __typename?: 'ArticleRow', id: number, name: string, quantity: number, stockTransactions: Array<{ __typename?: 'StockTransaction', id: string, quantity: number, price?: number | null, createdAt: any }> }>, supplier: { __typename?: 'Supplier', name: string, id: string } }> } };

export type InventoryGetSuppliersV2QueryVariables = Exact<{
  pagination?: InputMaybe<InventoryPaginationInputType>;
  ordering?: InputMaybe<InventoryOrderingInputTypeV2>;
  filtering?: InputMaybe<InventoryFilteringInputTypeV2>;
  searching?: InputMaybe<InventorySearchingInputType>;
}>;


export type InventoryGetSuppliersV2Query = { __typename?: 'Query', result: { __typename?: 'PaginatedSupplierResponse', total: number, list: Array<{ __typename?: 'Supplier', id: string, name: string, createdAt: any, country?: string | null }> } };


export const CreateInventoryCommentDocument = gql`
    mutation createInventoryComment($comment: CommentInputType!) {
  createComment(comment: $comment) {
    id
  }
}
    `;
export type InventoryCreateInventoryCommentMutationFn = Apollo.MutationFunction<InventoryCreateInventoryCommentMutation, InventoryCreateInventoryCommentMutationVariables>;

/**
 * __useCreateInventoryCommentMutation__
 *
 * To run a mutation, you first call `useCreateInventoryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryCommentMutation, { data, loading, error }] = useCreateInventoryCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateInventoryCommentMutation(baseOptions?: Apollo.MutationHookOptions<InventoryCreateInventoryCommentMutation, InventoryCreateInventoryCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InventoryCreateInventoryCommentMutation, InventoryCreateInventoryCommentMutationVariables>(CreateInventoryCommentDocument, options);
      }
export type CreateInventoryCommentMutationHookResult = ReturnType<typeof useCreateInventoryCommentMutation>;
export type CreateInventoryCommentMutationResult = Apollo.MutationResult<InventoryCreateInventoryCommentMutation>;
export type CreateInventoryCommentMutationOptions = Apollo.BaseMutationOptions<InventoryCreateInventoryCommentMutation, InventoryCreateInventoryCommentMutationVariables>;
export const DeleteInventoryCommentDocument = gql`
    mutation deleteInventoryComment($id: ID!) {
  deleteComment(id: $id)
}
    `;
export type InventoryDeleteInventoryCommentMutationFn = Apollo.MutationFunction<InventoryDeleteInventoryCommentMutation, InventoryDeleteInventoryCommentMutationVariables>;

/**
 * __useDeleteInventoryCommentMutation__
 *
 * To run a mutation, you first call `useDeleteInventoryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInventoryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInventoryCommentMutation, { data, loading, error }] = useDeleteInventoryCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInventoryCommentMutation(baseOptions?: Apollo.MutationHookOptions<InventoryDeleteInventoryCommentMutation, InventoryDeleteInventoryCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InventoryDeleteInventoryCommentMutation, InventoryDeleteInventoryCommentMutationVariables>(DeleteInventoryCommentDocument, options);
      }
export type DeleteInventoryCommentMutationHookResult = ReturnType<typeof useDeleteInventoryCommentMutation>;
export type DeleteInventoryCommentMutationResult = Apollo.MutationResult<InventoryDeleteInventoryCommentMutation>;
export type DeleteInventoryCommentMutationOptions = Apollo.BaseMutationOptions<InventoryDeleteInventoryCommentMutation, InventoryDeleteInventoryCommentMutationVariables>;
export const GetInventoryCommentsDocument = gql`
    query getInventoryComments($articleId: ID!) {
  comments(articleId: $articleId) {
    id
    content
    createdAt
    creator
  }
}
    `;

/**
 * __useGetInventoryCommentsQuery__
 *
 * To run a query within a React component, call `useGetInventoryCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryCommentsQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useGetInventoryCommentsQuery(baseOptions: Apollo.QueryHookOptions<InventoryGetInventoryCommentsQuery, InventoryGetInventoryCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetInventoryCommentsQuery, InventoryGetInventoryCommentsQueryVariables>(GetInventoryCommentsDocument, options);
      }
export function useGetInventoryCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetInventoryCommentsQuery, InventoryGetInventoryCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetInventoryCommentsQuery, InventoryGetInventoryCommentsQueryVariables>(GetInventoryCommentsDocument, options);
        }
export type GetInventoryCommentsQueryHookResult = ReturnType<typeof useGetInventoryCommentsQuery>;
export type GetInventoryCommentsLazyQueryHookResult = ReturnType<typeof useGetInventoryCommentsLazyQuery>;
export type GetInventoryCommentsQueryResult = Apollo.QueryResult<InventoryGetInventoryCommentsQuery, InventoryGetInventoryCommentsQueryVariables>;
export const UpdateInventoryArticleDocument = gql`
    mutation updateInventoryArticle($id: ID!, $article: ArticleInputType!) {
  updateArticle(id: $id, article: $article) {
    id
  }
}
    `;
export type InventoryUpdateInventoryArticleMutationFn = Apollo.MutationFunction<InventoryUpdateInventoryArticleMutation, InventoryUpdateInventoryArticleMutationVariables>;

/**
 * __useUpdateInventoryArticleMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryArticleMutation, { data, loading, error }] = useUpdateInventoryArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useUpdateInventoryArticleMutation(baseOptions?: Apollo.MutationHookOptions<InventoryUpdateInventoryArticleMutation, InventoryUpdateInventoryArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InventoryUpdateInventoryArticleMutation, InventoryUpdateInventoryArticleMutationVariables>(UpdateInventoryArticleDocument, options);
      }
export type UpdateInventoryArticleMutationHookResult = ReturnType<typeof useUpdateInventoryArticleMutation>;
export type UpdateInventoryArticleMutationResult = Apollo.MutationResult<InventoryUpdateInventoryArticleMutation>;
export type UpdateInventoryArticleMutationOptions = Apollo.BaseMutationOptions<InventoryUpdateInventoryArticleMutation, InventoryUpdateInventoryArticleMutationVariables>;
export const GetInventoryArticleDocument = gql`
    query getInventoryArticle($id: ID!) {
  article(id: $id) {
    artNo
    description
    height
    id
    length
    lowAlert
    name
    nameEn
    nameDe
    nameNo
    price
    productType
    currency
    purchasePrice
    purchaseCurrency
    internalPrice
    internalCurrency
    quantity
    sellOnline
    isInventory
    stockTransactions {
      id
      price
      quantity
      currency
      description
      transactedAt
      createdAt
      creator
      article {
        id
        artNo
      }
      purchaseOrder {
        id
      }
    }
    reservedQuantities {
      id
      quantity
      esbArticleRowId
      orderNumber
      preferredDeliveryDate
    }
    unit
    weight
    width
    category
  }
}
    `;

/**
 * __useGetInventoryArticleQuery__
 *
 * To run a query within a React component, call `useGetInventoryArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInventoryArticleQuery(baseOptions: Apollo.QueryHookOptions<InventoryGetInventoryArticleQuery, InventoryGetInventoryArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetInventoryArticleQuery, InventoryGetInventoryArticleQueryVariables>(GetInventoryArticleDocument, options);
      }
export function useGetInventoryArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetInventoryArticleQuery, InventoryGetInventoryArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetInventoryArticleQuery, InventoryGetInventoryArticleQueryVariables>(GetInventoryArticleDocument, options);
        }
export type GetInventoryArticleQueryHookResult = ReturnType<typeof useGetInventoryArticleQuery>;
export type GetInventoryArticleLazyQueryHookResult = ReturnType<typeof useGetInventoryArticleLazyQuery>;
export type GetInventoryArticleQueryResult = Apollo.QueryResult<InventoryGetInventoryArticleQuery, InventoryGetInventoryArticleQueryVariables>;
export const ArticlesV2Document = gql`
    query articlesV2($pagination: PaginationInputType, $ordering: OrderingInputTypeV2, $filtering: FilteringInputTypeV2, $lowAlert: Boolean, $searching: SearchingInputType) {
  result: articlesV2(pagination: $pagination, filtering: $filtering, ordering: $ordering, searching: $searching, lowAlert: $lowAlert) {
    total
    list {
      id
      name
      nameEn
      nameDe
      nameNo
      artNo
      quantity
      lowAlert
      reservedQuantitySum
      inventoryInTransit
      noStock
      productType
      category
    }
  }
}
    `;

/**
 * __useArticlesV2Query__
 *
 * To run a query within a React component, call `useArticlesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useArticlesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filtering: // value for 'filtering'
 *      lowAlert: // value for 'lowAlert'
 *      searching: // value for 'searching'
 *   },
 * });
 */
export function useArticlesV2Query(baseOptions?: Apollo.QueryHookOptions<InventoryArticlesV2Query, InventoryArticlesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryArticlesV2Query, InventoryArticlesV2QueryVariables>(ArticlesV2Document, options);
      }
export function useArticlesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryArticlesV2Query, InventoryArticlesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryArticlesV2Query, InventoryArticlesV2QueryVariables>(ArticlesV2Document, options);
        }
export type ArticlesV2QueryHookResult = ReturnType<typeof useArticlesV2Query>;
export type ArticlesV2LazyQueryHookResult = ReturnType<typeof useArticlesV2LazyQuery>;
export type ArticlesV2QueryResult = Apollo.QueryResult<InventoryArticlesV2Query, InventoryArticlesV2QueryVariables>;
export const StockArticlesV2Document = gql`
    query stockArticlesV2($pagination: PaginationInputType, $ordering: OrderingInputTypeV2, $filtering: FilteringInputTypeV2, $searching: SearchingInputType) {
  result: articlesV2(pagination: $pagination, filtering: $filtering, ordering: $ordering, searching: $searching) {
    list {
      id
      name
      price
      internalPrice
      internalCurrency
      quantity
      reservedQuantitySum
      orderedQuantity
      currency
      artNo
      createdAt
    }
    total
  }
}
    `;

/**
 * __useStockArticlesV2Query__
 *
 * To run a query within a React component, call `useStockArticlesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useStockArticlesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockArticlesV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filtering: // value for 'filtering'
 *      searching: // value for 'searching'
 *   },
 * });
 */
export function useStockArticlesV2Query(baseOptions?: Apollo.QueryHookOptions<InventoryStockArticlesV2Query, InventoryStockArticlesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryStockArticlesV2Query, InventoryStockArticlesV2QueryVariables>(StockArticlesV2Document, options);
      }
export function useStockArticlesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryStockArticlesV2Query, InventoryStockArticlesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryStockArticlesV2Query, InventoryStockArticlesV2QueryVariables>(StockArticlesV2Document, options);
        }
export type StockArticlesV2QueryHookResult = ReturnType<typeof useStockArticlesV2Query>;
export type StockArticlesV2LazyQueryHookResult = ReturnType<typeof useStockArticlesV2LazyQuery>;
export type StockArticlesV2QueryResult = Apollo.QueryResult<InventoryStockArticlesV2Query, InventoryStockArticlesV2QueryVariables>;
export const GetInventoryPurchaseOrderDocument = gql`
    query getInventoryPurchaseOrder($id: ID!) {
  purchaseOrder(id: $id) {
    id
    creator
    currency
    supplierRef
    status
    pdfText
    dateOfDispatch
    termsOfDelivery {
      value
      label
    }
    modeOfDelivery {
      value
      label
    }
    termsOfPayment {
      value
      label
    }
    stockTransactions {
      id
      quantity
      description
      createdAt
      transactedAt
      creator
      article {
        id
        artNo
      }
    }
    articleRows {
      id
      name
      quantity
      textRowReceivedQuantity
      receivedQuantity
      price
      unit
      article {
        id
        name
        artNo
      }
    }
    createdAt
    supplier {
      id
      name
      __typename
    }
  }
}
    `;

/**
 * __useGetInventoryPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetInventoryPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryPurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInventoryPurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<InventoryGetInventoryPurchaseOrderQuery, InventoryGetInventoryPurchaseOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetInventoryPurchaseOrderQuery, InventoryGetInventoryPurchaseOrderQueryVariables>(GetInventoryPurchaseOrderDocument, options);
      }
export function useGetInventoryPurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetInventoryPurchaseOrderQuery, InventoryGetInventoryPurchaseOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetInventoryPurchaseOrderQuery, InventoryGetInventoryPurchaseOrderQueryVariables>(GetInventoryPurchaseOrderDocument, options);
        }
export type GetInventoryPurchaseOrderQueryHookResult = ReturnType<typeof useGetInventoryPurchaseOrderQuery>;
export type GetInventoryPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetInventoryPurchaseOrderLazyQuery>;
export type GetInventoryPurchaseOrderQueryResult = Apollo.QueryResult<InventoryGetInventoryPurchaseOrderQuery, InventoryGetInventoryPurchaseOrderQueryVariables>;
export const GetPurchaseOrdersByArtNoV2Document = gql`
    query getPurchaseOrdersByArtNoV2($artNo: String!, $pagination: PaginationInputType, $ordering: OrderingInputTypeV2, $filtering: FilteringInputTypeV2, $searching: SearchingInputType) {
  result: purchaseOrdersByArtNoV2(artNo: $artNo, pagination: $pagination, filtering: $filtering, ordering: $ordering, searching: $searching) {
    total
    list {
      id
      createdAt
      creator
      status
      articleRows {
        id
        name
        quantity
        stockTransactions {
          id
          quantity
          price
          createdAt
        }
      }
      supplier {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseOrdersByArtNoV2Query__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersByArtNoV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersByArtNoV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersByArtNoV2Query({
 *   variables: {
 *      artNo: // value for 'artNo'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filtering: // value for 'filtering'
 *      searching: // value for 'searching'
 *   },
 * });
 */
export function useGetPurchaseOrdersByArtNoV2Query(baseOptions: Apollo.QueryHookOptions<InventoryGetPurchaseOrdersByArtNoV2Query, InventoryGetPurchaseOrdersByArtNoV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetPurchaseOrdersByArtNoV2Query, InventoryGetPurchaseOrdersByArtNoV2QueryVariables>(GetPurchaseOrdersByArtNoV2Document, options);
      }
export function useGetPurchaseOrdersByArtNoV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetPurchaseOrdersByArtNoV2Query, InventoryGetPurchaseOrdersByArtNoV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetPurchaseOrdersByArtNoV2Query, InventoryGetPurchaseOrdersByArtNoV2QueryVariables>(GetPurchaseOrdersByArtNoV2Document, options);
        }
export type GetPurchaseOrdersByArtNoV2QueryHookResult = ReturnType<typeof useGetPurchaseOrdersByArtNoV2Query>;
export type GetPurchaseOrdersByArtNoV2LazyQueryHookResult = ReturnType<typeof useGetPurchaseOrdersByArtNoV2LazyQuery>;
export type GetPurchaseOrdersByArtNoV2QueryResult = Apollo.QueryResult<InventoryGetPurchaseOrdersByArtNoV2Query, InventoryGetPurchaseOrdersByArtNoV2QueryVariables>;
export const GetSuppliersDocument = gql`
    query GetSuppliers {
  suppliers(pagination: {pageIndex: 0, pageSize: 1000}, ordering: {id: "name", desc: false}, filtering: []) {
    list {
      value: id
      label: name
      currency
    }
  }
}
    `;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<InventoryGetSuppliersQuery, InventoryGetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetSuppliersQuery, InventoryGetSuppliersQueryVariables>(GetSuppliersDocument, options);
      }
export function useGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetSuppliersQuery, InventoryGetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetSuppliersQuery, InventoryGetSuppliersQueryVariables>(GetSuppliersDocument, options);
        }
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<InventoryGetSuppliersQuery, InventoryGetSuppliersQueryVariables>;
export const GetPurchaseOrdersV2Document = gql`
    query getPurchaseOrdersV2($pagination: PaginationInputType, $ordering: OrderingInputTypeV2, $filtering: FilteringInputTypeV2, $searching: SearchingInputType) {
  result: purchaseOrdersV2(pagination: $pagination, filtering: $filtering, ordering: $ordering, searching: $searching) {
    total
    list {
      id
      createdAt
      creator
      status
      dateOfDispatch
      articleRows {
        id
        name
        quantity
        stockTransactions {
          id
          quantity
          price
          createdAt
        }
      }
      supplier {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseOrdersV2Query__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filtering: // value for 'filtering'
 *      searching: // value for 'searching'
 *   },
 * });
 */
export function useGetPurchaseOrdersV2Query(baseOptions?: Apollo.QueryHookOptions<InventoryGetPurchaseOrdersV2Query, InventoryGetPurchaseOrdersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetPurchaseOrdersV2Query, InventoryGetPurchaseOrdersV2QueryVariables>(GetPurchaseOrdersV2Document, options);
      }
export function useGetPurchaseOrdersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetPurchaseOrdersV2Query, InventoryGetPurchaseOrdersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetPurchaseOrdersV2Query, InventoryGetPurchaseOrdersV2QueryVariables>(GetPurchaseOrdersV2Document, options);
        }
export type GetPurchaseOrdersV2QueryHookResult = ReturnType<typeof useGetPurchaseOrdersV2Query>;
export type GetPurchaseOrdersV2LazyQueryHookResult = ReturnType<typeof useGetPurchaseOrdersV2LazyQuery>;
export type GetPurchaseOrdersV2QueryResult = Apollo.QueryResult<InventoryGetPurchaseOrdersV2Query, InventoryGetPurchaseOrdersV2QueryVariables>;
export const GetSuppliersV2Document = gql`
    query getSuppliersV2($pagination: PaginationInputType, $ordering: OrderingInputTypeV2, $filtering: FilteringInputTypeV2, $searching: SearchingInputType) {
  result: suppliersV2(pagination: $pagination, filtering: $filtering, ordering: $ordering, searching: $searching) {
    total
    list {
      id
      name
      createdAt
      country
    }
  }
}
    `;

/**
 * __useGetSuppliersV2Query__
 *
 * To run a query within a React component, call `useGetSuppliersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filtering: // value for 'filtering'
 *      searching: // value for 'searching'
 *   },
 * });
 */
export function useGetSuppliersV2Query(baseOptions?: Apollo.QueryHookOptions<InventoryGetSuppliersV2Query, InventoryGetSuppliersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryGetSuppliersV2Query, InventoryGetSuppliersV2QueryVariables>(GetSuppliersV2Document, options);
      }
export function useGetSuppliersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryGetSuppliersV2Query, InventoryGetSuppliersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryGetSuppliersV2Query, InventoryGetSuppliersV2QueryVariables>(GetSuppliersV2Document, options);
        }
export type GetSuppliersV2QueryHookResult = ReturnType<typeof useGetSuppliersV2Query>;
export type GetSuppliersV2LazyQueryHookResult = ReturnType<typeof useGetSuppliersV2LazyQuery>;
export type GetSuppliersV2QueryResult = Apollo.QueryResult<InventoryGetSuppliersV2Query, InventoryGetSuppliersV2QueryVariables>;