import React, {useCallback, useRef, useState} from "react";
import {useCreatePackageMutation} from "../generated/graphql";
import NewFormRenderer from "../common/NewFormRenderer";
import {Button} from "reactstrap";
import {PackageTypes} from "./PackageTypes";
import {ok} from "assert";
import NotificationPopup from "../common/lib/NotificationPopup";
import Auth from "../Auth";
import AddArticleRowForPackagePanel from "./component/AddArticleRowForPackagePanel";

export const PackageShareFormDefinition = [
    {name: "mark", label: "Shipping mark", type: "text", required: true},
    {
        name: "type",
        type: "select",
        options: PackageTypes,
        required: true
    },
    {name: "description", type: "textarea"},
    {name: "quantity", type: "number"},
    {name: "weight", type: "number", label: "Weight(kg)", required: true},
    {name: "length", type: "number", label: "Length(cm)"},
    {name: "width", type: "number"},
    {name: "height", type: "number"},
    {name: "loadingMetres", type: "number", label: 'Load mtrs'},
    {
        name: "volume", type: "number", label: "volume(m3)", disabled: true,
        transformValue: ({object}) =>
        {
            const length = object.length ?? 0
            const width = object.width ?? 0
            const height = object.height ?? 0
            return length * width * height / 10000000
        }
    },

]


type Props = {
    customOnCreateSuccess?: () => void,
    history?: any
    orderId?: string
    defaultSelectedArticleRowIdList?: string[]
    openSearchOrder?: boolean
}

export default (props: Props) =>
{
    const {customOnCreateSuccess, orderId, defaultSelectedArticleRowIdList, openSearchOrder} = props

    const [createdPackage, setCreatedPackage] = useState<any>({})

    const articleRowsForPackageRef = useRef<any>()

    const [createPackage] = useCreatePackageMutation({refetchQueries: ["getOrder", "spiralStairsInProductionV2", "getPackages"]})

    const onChange = useCallback(async (key, value) =>
    {
        setCreatedPackage({...createdPackage, [key]: value})
    }, [setCreatedPackage, createdPackage])

    const onCreateClick = useCallback(async () =>
    {
        try
        {
            const packageItems = articleRowsForPackageRef.current?.getPackageItemsInput()

            const response = await createPackage({
                variables: {
                    packageInput: {
                        ...createdPackage,
                        tenantId: Auth.getTenant(),
                        packageItemInputs: packageItems
                    }
                }
            })
            ok(!response.errors, `${response.errors}`)

            const id = response.data?.createPackage?.id
            NotificationPopup.success(`Create package success`)

            if (customOnCreateSuccess)
            {
                customOnCreateSuccess()
            } else
            {
                props.history.push(`/package-detail/${id}`)
            }
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [createdPackage, createPackage, props.history, customOnCreateSuccess])

    const isCreateButtonDisabled = useCallback(() =>
    {
        const required = PackageShareFormDefinition.filter((e) => e.required)
        const keys = Object.keys(createdPackage)

        return !required.every((e) => keys.includes(e.name))
    }, [createdPackage])

    const onCommonSizeClick = useCallback(() =>
    {
        setCreatedPackage(prevState =>
        {
            return {
                ...prevState,
                length: 120,
                height: 120,
                width: 80,
            }
        })
    }, [setCreatedPackage])

    return <div>
        <NewFormRenderer
            formDefinition={PackageShareFormDefinition}
            object={createdPackage}
            columns={3}
            onChange={onChange}/>

        <p>Common Size</p>
        <Button size="sm" outline color="primary" onClick={onCommonSizeClick}
                style={{marginBottom: '20px'}}>120*80*120</Button>

        <AddArticleRowForPackagePanel ref={articleRowsForPackageRef}
                                      packageItems={undefined}
                                      orderId={orderId}
                                      openSearchOrder={openSearchOrder}
                                      defaultSelectedArticleRowIdList={defaultSelectedArticleRowIdList}/>
        <Button color="success"
                style={{marginTop: "16px"}}
                disabled={isCreateButtonDisabled()}
                onClick={onCreateClick}>Create</Button>
    </div>
}
