
export const environment = {
    LOCAL: 'local',
    DEVELOP: 'develop',
    PRODUCTION: 'production'
}

const hostName = {
    develop2: "test2crm.eurostair.se",
    develop: "testcrm.eurostair.se",
    production: "crm.eurostair.se",
}
const getConfig = (hostname) => {
    switch (hostname){
        case hostName.develop2:
        case hostName.develop:
            return {
                environment: environment.DEVELOP,
                BACKEND_URI:"https://testbackend.eurostair.se/",
                GRAPHQL_URI:"https://testbackend.eurostair.se/graphql",
                WS_URI:"wss://testbackend.eurostair.se/wss/graphql",
                QM_URI:"https://develop.eurostair.se/",
                REDIRECT_URI:`https://${hostname}`,
                INVENTORY_URI: "https://testinventory.eurostair.se/backend/",
                INVENTORY_GRAPHQL_URI: "https://testinventory.eurostair.se/backend/graphql"
            };
        case hostName.production:
            return {
                environment: environment.PRODUCTION,
                BACKEND_URI:"https://backend.eurostair.se/",
                GRAPHQL_URI:"https://backend.eurostair.se/graphql",
                WS_URI:"wss://backend.eurostair.se/wss/graphql",
                QM_URI:"https://configurator.eurostair.se/",
                REDIRECT_URI:"https://crm.eurostair.se",
                INVENTORY_URI: "https://inventory.eurostair.se/backend/",
                INVENTORY_GRAPHQL_URI: "https://inventory.eurostair.se/backend/graphql"
            };
        default:
            return {
                environment: environment.LOCAL,
                BACKEND_URI:"https://testbackend.eurostair.se/",
                GRAPHQL_URI:"https://testbackend.eurostair.se/graphql",
                WS_URI:"wss://testbackend.eurostair.se/wss/graphql",
                REDIRECT_URI:"http://localhost:3002/",
                QM_URI:"https://develop.eurostair.se/",
                INVENTORY_URI: "http://localhost:4001/",
                INVENTORY_GRAPHQL_URI: "http://localhost:4001/graphql",
                // BACKEND_URI:"https://testbackend.eurostair.se/",
                // GRAPHQL_URI:"https://testbackend.eurostair.se/graphql",
                // WS_URI:"ws://testbackend.eurostair.se/graphql",
                // QM_URI:"https://develop.eurostair.se/",
                // REDIRECT_URI:`https://${hostname}`,
                // INVENTORY_URI: "https://testinventory.eurostair.se/backend/",
                // INVENTORY_GRAPHQL_URI: "https://testinventory.eurostair.se/backend/graphql"
            };
    }
}

const config = getConfig(window.location.hostname);
export default config;
