import {
    usePriceListVersionsForStraightStairQuery
} from "../generated/graphql";
import {Card, CardBody, CardHeader, Col, Row, TabContent, TabPane} from "reactstrap";
import BackButtons from "../common/BackButtons";
import React, {useCallback, useState} from "react";
import useStraightStairV2DetailHook from "./hooks/useStraightStairV2DetailHook";
import LoadingView from "common/LoadingView";
import {
    OnCopySegmentClick,
    OnDeleteSegmentClick
} from "./StraightStairV2Types";
import StraightStairExtraRailingPanel from "./components/StraightStairExtraRailingPanel";
import StraightStairSurfaceTreatmentModal from "./components/StraightStairSurfaceTreatmentModal";
import StraightStairExtraPanel from "./components/StraightStairExtraPanel";
import SegmentTabPane from "./components/SegmentTabPane";
import StraightStairPriceCalculationPanel from "./components/StraightStairPriceCalculationPanel";
import StairNavBar from "./components/StairNavBar";
import StairTabPane from "./components/StairTabPane";
import {STRAIGHT_STAIR_TAB_STAIR} from "./StraightStairConstants";
import {getSegmentId} from "./StraightStairUtils";
import StraightStairSeparateKickPlatePanel from "./components/StraightStairSeparateKickPlatePanel";
import {Prompt} from "react-router";

const HIDDEN_SEPARATE_KICK_PLATE = true

export default (props) =>
{
    const {data: versionList} = usePriceListVersionsForStraightStairQuery()
    const [openSurfaceTreatmentModal, setOpenSurfaceTreatmentModal] = useState(false)

    const {
        isLoading, save, hasUnsavedChange,
        straightStairV2, updateStraightStair,
        activeTab, updateActiveTab,
        articleRow, setArticleRow,
        addSegment, deleteSegment, updateSegment, copySegment,
        updateSupports,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
        addExtra, deleteExtra, updateExtra,
        updateSurfaceTreatments,
        calculateNumberOfSteps,
        validationErrors, updatePOPrice, getPriceError,
        priceSuggestion,
        isAsnEnabled, isDoubleRailingAndExtendedEnabled,
        stringerOptions,
       addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
    } = useStraightStairV2DetailHook({
        articleRowId: props.match.params.articleRowId,
        articleId: props.match.params.id
    })

    const toggleSurfaceTreatmentModal = useCallback(() =>
    {
        setOpenSurfaceTreatmentModal(prevState => !prevState)
    }, [])

    const onDeleteSegmentClick: OnDeleteSegmentClick = useCallback(async (num, id) =>
    {
        if (window.confirm(`Are you sure you want to delete Section ${num}?`))
        {
            await deleteSegment(id)
        }
    }, [deleteSegment])

    const onCopySegmentClick: OnCopySegmentClick = useCallback(async (num, id) =>
    {
        if (window.confirm(`Are you sure you want to copy Section ${num}?`))
        {
            await copySegment(id)
        }
    }, [copySegment])

    return <>
        <Prompt when={hasUnsavedChange} message={`You have unchanged changes, are you sure you want to leave?`} />
        <Row>
            <Col sm={2}>
                <BackButtons articleRowId={props.match.params.articleRowId}/>
            </Col>
        </Row>
        {
            straightStairV2 && <>
                <Row style={{marginTop: "10px", maxWidth: "1920"}}>
                    {(versionList && articleRow) &&
                        <Col sm={6} md={7} xl={5}>
                            <StraightStairPriceCalculationPanel articleRow={articleRow} setArticleRow={setArticleRow}
                                                                straightStair={straightStairV2}
                                                                hasUnsavedChanged={hasUnsavedChange}
                                                                validationErrors={validationErrors}
                                                                onSaveClick={save}
                                                                versionList={versionList.priceListVersionsForStraightStair}
                                                                onVersionChange={updateStraightStair}
                                                                onUpdatePOClick={updatePOPrice}
                                                                priceSuggestion={priceSuggestion}
                                                                getPriceError={getPriceError}/>
                        </Col>
                    }

                    <Col sm={5} xl={4}>
                        <StraightStairExtraPanel extras={straightStairV2.extras}
                                                 onAddClicked={addExtra}
                                                 onDeleteClicked={deleteExtra}
                                                 onUpdatedClicked={updateExtra}/>
                    </Col>
                </Row>

                <Row style={{marginTop: "10px"}}>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <StairNavBar activeTab={activeTab} onTabClick={updateActiveTab}
                                             segments={straightStairV2.segments}
                                             straightStairV2Id={straightStairV2.id}
                                             onAddClick={addSegment}
                                             onDeleteClick={onDeleteSegmentClick}
                                             onCopyClick={onCopySegmentClick}
                                />
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab} style={{maxWidth: "1920px"}}>
                                    <TabPane tabId={STRAIGHT_STAIR_TAB_STAIR}>
                                        <Row>
                                            <Col>
                                                <StairTabPane straightStairV2={straightStairV2}
                                                              onChange={updateStraightStair}
                                                              toggleSurfaceTreatmentModal={toggleSurfaceTreatmentModal}
                                                              isAsnEnabled={isAsnEnabled}
                                                              surfaceTreatments={straightStairV2.surfaceTreatments}
                                                              stringerOptions={stringerOptions}/>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col style={{maxWidth: '1200px'}}>
                                                <StraightStairExtraRailingPanel straightStairV2={straightStairV2}
                                                                                updateStraightStair={updateStraightStair}
                                                                                extraRailings={straightStairV2.extraRailings}
                                                                                updateExtraRailing={updateExtraRailing}
                                                                                addExtraRailing={addExtraRailing}
                                                                                deleteExtraRailing={deleteExtraRailing}/>

                                            </Col>
                                        </Row>
                                        {
                                            !HIDDEN_SEPARATE_KICK_PLATE && <Row>
                                                <Col style={{maxWidth: '600px'}}>
                                                    <StraightStairSeparateKickPlatePanel
                                                        separateKickPlates={straightStairV2.separateKickPlates}
                                                        updateSeparateKickPlate={updateSeparateKickPlate}
                                                        addSeparateKickPlate={addSeparateKickPlate}
                                                        deleteSeparateKickPlate={deleteSeparateKickPlate}/>
                                                </Col>
                                            </Row>
                                        }
                                    </TabPane>

                                    {
                                        straightStairV2.segments.map((segment) =>
                                        {
                                            const key = getSegmentId(segment.id);
                                            return <TabPane key={key} tabId={key}>
                                                <SegmentTabPane segment={segment} onChange={updateSegment}
                                                                onCalculateNumberOfStepsClick={calculateNumberOfSteps}
                                                                stairRailingStandard={straightStairV2.railingStandard}
                                                                stairRailingType={straightStairV2.railingType}
                                                                stairStringerMaterial={straightStairV2.stringerMaterial}
                                                                isDoubleRailingAndExtendedEnabled={isDoubleRailingAndExtendedEnabled}
                                                                updateSegmentSupports={updateSupports}/>
                                            </TabPane>
                                        })
                                    }
                                </TabContent>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

                <StraightStairSurfaceTreatmentModal surfaceTreatments={straightStairV2.surfaceTreatments}
                                                    isOpen={openSurfaceTreatmentModal}
                                                    toggle={toggleSurfaceTreatmentModal}
                                                    onUpdate={updateSurfaceTreatments}/>
            </>
        }

        {isLoading && <LoadingView/>}
    </>
}


