import React from 'react';
import {Col, FormGroup, FormText, Label, Row} from 'reactstrap';
import _, {trim} from 'lodash';
import {formatCurrency, labelize} from "../UTIL";
import SelectUser from "common/SelectUser";
import LocaleQuery from './graphql/LocaleQuery.graphql';
import Picker from "./Picker";
import SearchQuery from './graphql/FreeTextSearchQuery.graphql';
import {Input, selectRenderer} from "./FormRenderer";
import Dynamic from "./Dynamic";
import DatePickerWithWeek from "./DatePickerWithWeek";
import TimePicker from "./TimePicker";


const select = (field,onChange,value)=>
{
    return selectRenderer(field,value,e=>
    {
        let value = null;
        if(_.isArray(e))
        {
            value = e;
        }
        else
        {
            if(e==null)
            {
                value = null;
            }
            else if(e.id != null)
            {
                value = e.id;
            }
            else if(e.value != null)
            {
                value = e.value;
            }
        }
        onChange(field.name,value);
    })
};

const dateTime = (field,value,onChange)=>
{
    return <Dynamic disablePast={field.disablePast} value={value} onChange={(date)=>
    {
        console.log(`selected ${date.toString()}`);
        onChange(field.name,date.toDate());
    }} load={()=>import("./DateTimePicker")} />
};

const numberInputOnBlur = (e, field, onChange) => {
    const value = e.target.value === "" ? null : parseFloat(e.target.value);
    const {max, min, step} = field
    if (max && value > max) {
        onChange(field.name, max)
        return
    }
    if (min && value < min) {
        onChange(field.name, min);
        return
    }
    if (step) {
        const minValue = min ?? 0
        if ((value - minValue) % step !== 0) {
            const correctedValue = minValue + Math.round((value - minValue) / step) * step
            onChange(field.name, correctedValue);
        }
    }
}

const inputRender = (field,object,onChange)=>
{
    switch(field.type)
    {
        case 'select':return select(field,onChange,object[field.name]);
        case 'custom':return field.input(object,(val, config, targetName)=>onChange(targetName || field.name, val))
        case 'userPicker':
            return <SelectUser userId={object[field.name]} onChange={onChange}/>;
        case 'number':
            return (<Input required={field.required} min={field.min} step={field.step}
                           max={field.max} type="number" value={object[field.name]==null?'':object[field.name]}
                           disabled={field.disabled}
                           onBlur={(e) => {
                               numberInputOnBlur(e, field, onChange)
                               field.onCustomBlur?.(e)
                           }}
                           onChange={e=>
                           {
                               const value = e.target.value === "" ? null : parseFloat(e.target.value);
                               onChange(field.name, value);
                           }}/>);
        case 'currency':
            return (<div>
                <Input required={field.required} min={field.min} max={field.max} type="number"
                       value={object[field.name]==null?'':object[field.name]}
                       onChange={e=>
                       {
                           if(e.target.value !== "")
                           {
                               onChange(field.name,parseFloat(e.target.value))
                           }

                       }}/>
                <small>{`${formatCurrency(object[field.name])}`}</small>

            </div>);
        case 'localePicker':return select({...field,query:LocaleQuery,type:"select"},object[field.name],onChange);
        case 'custom-search':return <Picker startValue={field.startValue} required={field.required} mapFn={field.mapFn}
                                            onChange={e=>onChange(field.name,e.value)} query={field.query} />;
        case 'search':return <Picker types={field.types} filter={field.filter} startValue={field.startValue}
                                     required={field.required} onChange={e=>onChange(field.name,e.value,e.type)}
                                     getSearchAllTenant={field.getSearchAllTenant}
                                     query={SearchQuery} defaultValue={field.defaultValue}/>;
        case 'currencyPicker':return select({...field,type:"select",options:["SEK","NOK","DKK","EUR"].map(c=>({id:c,name:c}))},
        onChange,object[field.name]);
        case 'checkbox':
            return <>
                <br/>
                <Input style={{ marginLeft: 4 }} className='big-checkbox' required={field.required} type={'checkbox'} checked={object[field.name]}
                       disabled={field.disabled}
                       onChange={e => onChange(field.name, e.target.checked)}/>
            </>
        case 'hidden':
            return <input type='hidden' value={object[field.name]}/>;
        case "datetime":
            return dateTime(field,object[field.name],onChange);
        case "date":
            return <DatePickerWithWeek defaultValue={object[field.name]}
                                       onChange={(date) => {
                                           onChange(field.name, date.value)
                                       }}/>
        case "time":
            return <TimePicker defaultValue={object[field.name]}
                               onChange={({value}) => {
                                   onChange(field.name, value)
                               }}/>
        // case "file":
        //     return this.filePicker(field);
        default:
            return (<Input disabled={field.disabled} required={field.required}
                           type={field.type||"text"} value={object[field.name]||""}
                           onChange={e=>onChange(field.name,e.target.value)}/>);
    }
};


const formColumn = (formDefinition,object,onChange, columnClassName="") => {
    return formDefinition.map(field => {
        const {
            name,
            label,
            text,
            required,
            hint,
            hideField,
            hiddenFieldMessage,
            className:currentClassName="",
            style,
        } = field;
        const className = trim(currentClassName+" "+columnClassName)

        if(hideField && hideField(object))
        {
            return hiddenFieldMessage||null;
        } else {
            return <FormGroup key={name} className={className} style={{...style, paddingBottom: 2}}>
                <Label>{label||labelize(name)} {required && <small style={{color:"grey"}}>(required)</small>}</Label>
                {hint ? hint : null}
                {inputRender(field,object,onChange)}
                <FormText style={{whiteSpace: "pre-line"}}>{text}</FormText>
            </FormGroup>
        }
    });
};

const formBody = (formDefinition,columns,object,onChange, columnClassName)=>
{
    if(columns)
    {
        const chunked = _.chunk(formDefinition,columns);
        return chunked.map((chunk,index) =>
        <Row key={index}>
            {chunk.map((col,index)=>
            <Col key={index} sm={12 / columns}>
                {formColumn([col],object,onChange, columnClassName)}
            </Col>
            )}
        </Row>
        );
    }
    else
    {
        return formColumn(formDefinition,object,onChange, columnClassName);
    }
};


export default ({formDefinition,columns,object,onChange,customSubmit=<></>, columnClassName=""}) => {
    return <div>
        {formBody(formDefinition,columns,object,onChange, columnClassName)}
        {customSubmit && customSubmit}
    </div>
}


export const prepare = (object,formDefinition)=>
{
    let clone = _.assign({},object);
    for(let key in clone)
    {
        if(clone.hasOwnProperty(key) && _.isArray(clone[key]))
        {
            clone[key] = _.map(clone[key],o=>_.omit(o,'__typename'));
        }
        else if(clone.hasOwnProperty(key) && clone[key] != null && typeof(clone[key]) === 'object'){
            clone[key + "Id"] = clone[key].id || clone[key].username;
        }
    }
    const model = {};
    console.log("clone", clone)
    formDefinition.forEach(def =>
    {
        if(clone[def.name] == null && def.defaultValue != null)
        {
            model[def.name] = def.defaultValue;
        }
        else
        {
            model[def.name] = clone[def.name];
        }
    });

    return model;
};
