import React, {memo, useCallback, useMemo, useState} from 'react';
import {OrderStatuses} from "../UTIL";
import _, {sum, sumBy} from 'lodash';
import {articleDetailLink} from "../workflow-forms/DrawJob/ArticleList";
import {Badge, Button, Modal, ModalBody, UncontrolledTooltip} from "reactstrap";
import SurfaceTreatmentTag from "../common/SurfaceTreatmentTag";
import Up from "../common/icons/Up";
import DoubleUp from "../common/icons/DoubleUp";
import Wood from "../common/icons/Wood";
import OrderInvoices from "../order/OrderInvoices";
import {getPaymentRequirementStatus, PaymentRequirementStatus} from "../common/PaymentRequirement";
import {
    Comparator, FilteringItemInputType, InvoiceStatus, LogisticArticleRowFragmentFragment,
    LogisticsOrdersV2Document, OrderPackageStatus,
    TermsOfPaymentType
} from "../generated/graphql";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import ColumnOrderNumber from "../common/data-grid/columns/ColumnOrderNumber";
import ColumnsCompany from "../common/data-grid/columns/ColumnsCompany";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnComments from "../common/data-grid/columns/ColumnComments";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import {format, getWeek} from 'date-fns';
import ColumnTenantId from "../common/data-grid/columns/ColumnTenantId";
import {ColumnDefinition} from "../common/data-grid/ColumnDefinition";
import {getLinkedShipments} from "../order/OrderShipmentPanel";
import {Link} from "react-router-dom";

const KEY_TABLE = "LOGISTICS_ORDER"

type ArticleFilter = {
    text: string,
    filter: FilteringItemInputType[]
}

const defaultSort = {sortModel: [{field: 'preferredDeliveryDate', sort: 'asc'}]}

const defaultFilter = {field: "status", operator: "in", value: ["waitDeliver"]}

const filterOrdersWithStockArticles = {
    key: 'articleRows.some.stockArticleId',
    value: 'null',
    comparator: Comparator.NotEqual
}

const filterOrdersWithOnlyStockArticles = {
    key: 'articleRows.every.stockArticleId',
    value: 'null',
    comparator: Comparator.NotEqual
}

const articleFilterButtons = [
    {
        text: "All Orders",
        filter: []
    },
    {
        text: "Orders with stock articles",
        filter: [filterOrdersWithStockArticles]
    },
    {
        text: "Orders with ONLY stock articles",
        filter: [filterOrdersWithOnlyStockArticles]
    }
]

const statusFilterButtons = [
    {
        name: "Waiting for suppliers",
        filters: {items: [{field: "status", operator: "in", value: ["waitSuppliers"]}]},
    },
    {
        name: "Waiting to be delivered",
        filters: {items: [{field: "status", operator: "in", value: ["waitDeliver"]}]},
    },
    {
        name: "Delivered",
        filters: {items: [{field: "status", operator: "in", value: ["delivered"]}]},
    },
]

const packageStatusButtons = [
    { tag: null, label: "All status" },
    { tag: OrderPackageStatus.NotStarted, label: "Not Started" },
    { tag: OrderPackageStatus.InProgress, label: "In Progress" },
    { tag: OrderPackageStatus.Completed, label: "Completed" }
];

const TermsOfPaymentBadge = (props) =>
{
    const termsOfPaymentType = props.termsOfPayment?.type
    const value = props.termsOfPayment?.name

    switch (termsOfPaymentType)
    {
        case TermsOfPaymentType.ImmediatePayment:
            return <Badge color="danger">{value}</Badge>
        case TermsOfPaymentType.Prepayment50:
            return <Badge color="warning">{value}</Badge>
        default:
            return <Badge color="light">{value}</Badge>
    }
}

const PaymentRequirements = (props) =>
{
    const status = getPaymentRequirementStatus(props.invoices.filter((e) => e.status !== InvoiceStatus.Hold),
        props.orderSum?.total, props.termsOfPaymentType)

    return <div>
        {status !== null &&
            <div>
                <Badge size='lg'
                       color={PaymentRequirementStatus.color(status)}>{PaymentRequirementStatus.label(status)}</Badge>
                <br/>
                <Button size="sm" color="primary" outline onClick={props.onClick} style={{marginTop: '3px'}}>
                    invoice
                </Button>
            </div>
        }
    </div>
}

const ArticlePackageBadge = memo(({articleRow}: { articleRow: LogisticArticleRowFragmentFragment }) =>
{
    const packageQuantity = articleRow.packageItems?.map((e) =>
    {
        return e.package.quantity * e.quantity ?? 0
    })
    const totalPackagedQuantity = sum(packageQuantity)
    const articleRowQuantity = articleRow.quantity ?? 0
    return <PackageBadge totalPackagedQuantity={totalPackagedQuantity} articleRowQuantity={articleRowQuantity}/>
})

const StockArticlePackageBadge = memo(({articleRows}: { articleRows: LogisticArticleRowFragmentFragment[] }) =>
{
    const packageQuantity = articleRows.flatMap((e) => e.packageItems)?.map((e) =>
    {
        return (e?.package?.quantity ?? 0) * (e?.quantity ?? 0)
    })
    const totalPackagedQuantity = sum(packageQuantity)
    const articleRowQuantity = sumBy(articleRows, e => e?.quantity ?? 0)
    return <PackageBadge totalPackagedQuantity={totalPackagedQuantity} articleRowQuantity={articleRowQuantity}/>
})

const PackageBadge = memo(({articleRowQuantity, totalPackagedQuantity}: {
    articleRowQuantity: number,
    totalPackagedQuantity: number
}) =>
{
    if (totalPackagedQuantity === 0)
    {
        return null
    }
    if (totalPackagedQuantity < articleRowQuantity)
    {
        return <Badge color='info'>partially packed</Badge>
    }

    return <Badge color='primary'>packed</Badge>
})

const ArticleRowCell = memo(({articleRows}: { articleRows: LogisticArticleRowFragmentFragment[] }) =>
{
    const nonStockArticleRows = articleRows.filter((ar) => ar.article && !ar.isStockArticle)
    const stockArticles = articleRows.filter((ar) => ar.isStockArticle)
    return <div>
        {nonStockArticleRows.map(ar =>
            {
                const article = ar.article
                if (!article)
                {
                    return '-'
                }
                const hasWoodSteps = _.get(article, "spiralStair.stepType") === "oak30mm" || _.get(article, "spiralStair.innerRailingType") === "oak";
                const woodId = `wood-${article.id}`;

                const spiralStair = article.spiralStair
                return <p key={article.id} style={{margin: 0}}>
                    {articleDetailLink(article, article.artNo)}
                    {hasWoodSteps && <><UncontrolledTooltip target={woodId}>Wooden
                        steps</UncontrolledTooltip><span id={woodId}
                                                         style={{
                                                             textDecoration: "underline",
                                                             fontSize: "5px"
                                                         }}><Wood/></span></>}
                    {spiralStair && <>
                        {(spiralStair.surfaceTreatmentOverview && spiralStair.platingType) &&
                            <SurfaceTreatmentTag spiralStair={{
                                ...spiralStair,
                                surfaceTreatmentOverview: spiralStair.surfaceTreatmentOverview,
                                platingType: spiralStair.platingType
                            }}/>}
                    </>}
                    <ArticlePackageBadge articleRow={ar}/>
                </p>
            }
        )}
        {stockArticles.length > 0 &&
            <div style={{margin: 0}}>{stockArticles.length} Stock articles
                <StockArticlePackageBadge articleRows={stockArticles}/>
            </div>}
    </div>
})

const ShipmentCell = memo(({articleRows}: { articleRows: LogisticArticleRowFragmentFragment[] }) =>
{
    const linkedShipments = useMemo(() =>
    {
        return getLinkedShipments(articleRows)
    }, [articleRows])

    return <div>
        {linkedShipments.map((e) => <p style={{margin: 0}}>
            <Link to={`/shipment-detail/${e?.id}`}>{e?.number}</Link>
        </p>)}
    </div>
})

export default () =>
{
    const [modal, setModal] = useState(false);
    const [invoices, setInvoices] = useState(null);
    const [articleFilter, setArticleFilter ] = useState<ArticleFilter>(articleFilterButtons[0])
    const [packageStatus, setPackageStatus] = useState<OrderPackageStatus | null>(null)

    const columnAssignee = ColumnAssignee();

    const toggle = useCallback((invoices) =>
    {
        setInvoices(invoices)
        setModal(prevState => !prevState)
    }, [])

    const logisticOrderColumns: ColumnDefinition[] = useMemo(() =>
    {
        return [
            ColumnTenantId(),
            ColumnOrderNumber(),
            ColumnsCompany(),
            {
                field: 'articleRows',
                headerName: 'Articles',
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                {
                    return <ArticleRowCell articleRows={params.value}/>
                },
            },
            ColumnsStatus(OrderStatuses),
            {
                field: 'preferredDeliveryDate',
                headerName: 'Preferred delivery date',
                type: 'date',
                valueGetter: ({value}) => value && new Date(value),
                renderCell: (params) =>
                {
                    let prioritisationIcon: React.ReactNode | null = null;
                    const tooltipId = "prioritisationTooltip_" + params.id;
                    let tip = "";
                    const prioritisationLevel = params.row['prioritisation']?.level
                    if (prioritisationLevel)
                    {
                        switch (prioritisationLevel)
                        {
                            case 1:
                                prioritisationIcon = <Up/>;
                                tip = "high prioritisation";
                                break;
                            case 2:
                                prioritisationIcon = <DoubleUp/>;
                                tip = "very high prioritisation";
                                break;
                            default:
                                break;
                        }
                    }

                    return <>
                        {prioritisationIcon && <UncontrolledTooltip target={tooltipId}>{tip}</UncontrolledTooltip>}
                        <span id={tooltipId}>
                                {prioritisationIcon} {format(params.value, 'yyyy-MM-dd')} {prioritisationIcon}
                            </span>
                    </>;
                }
            },
            {
                field: 'termsOfPayment',
                headerName: 'Terms of payment',
                type: 'singleSelect',
                customFilterField: 'termsOfPayments.type',
                customSortField: 'termsOfPayments.type',
                valueOptions: [
                    {label: 'Immediate payment', value: TermsOfPaymentType.ImmediatePayment},
                    {label: '10 days', value: TermsOfPaymentType.Day10},
                    {label: '14 days', value: TermsOfPaymentType.Day14},
                    {label: '20 days', value: TermsOfPaymentType.Day20},
                    {label: '30 days', value: TermsOfPaymentType.Day30},
                    {label: '45 days', value: TermsOfPaymentType.Day45},
                    {label: '60 days', value: TermsOfPaymentType.Day60},
                    {label: '90 days', value: TermsOfPaymentType.Day90},
                    {label: '50% prepayment, 50% after delivery', value: TermsOfPaymentType.Prepayment50},
                    {label: 'efterkrav', value: TermsOfPaymentType.CashOnDelivery},
                ],
                renderCell: (params) =>
                {
                    return <div>
                        <TermsOfPaymentBadge termsOfPayment={params.value}/>
                        <PaymentRequirements invoices={params.row.invoices}
                                             orderSum={params.row.orderSum}
                                             termsOfPaymentType={params.value?.type}
                                             onClick={() => toggle(params.row.invoices)}/>
                    </div>
                }
            },
            {
                field: 'shipment',
                headerName: 'Shipments',
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                {
                    return <ShipmentCell articleRows={params.row.articleRows}/>
                },
            },
            ColumnComments(),
            columnAssignee,
            ColumnId(),
            {
                headerName: "Week",
                type: 'date',
                field: "preferredDeliveryDate2",
                customSortField: "preferredDeliveryDate",
                filterable: false,
                renderCell: (params) =>
                {
                    return <span>{getWeek(new Date(params.row.preferredDeliveryDate))}</span>
                }
            }
        ]
    }, [toggle, columnAssignee])

    return <div>
        <h6>Article filter</h6>
        {
            articleFilterButtons.map((e) =>
            {
                return <Button color="primary"
                               style={{marginRight: "8px"}}
                               outline={articleFilter !== e}
                               onClick={() => setArticleFilter(e)}>{e.text}</Button>
            })
        }

        <div style={{height: "8px"}}/>
        <h6>Package status filter (Cached Data)</h6>
        {packageStatusButtons.map(({tag, label}) => (
            <Button
                color="info"
                style={{marginRight: "8px"}}
                outline={packageStatus !== tag}
                onClick={() => setPackageStatus(tag)}>
                {label}
            </Button>
        ))}

        <div style={{height: "8px"}}/>
        <h6>Order status filter</h6>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: logisticOrderColumns,
                initState: {
                    sorting: defaultSort,
                    filter: {filterModel: {items: [defaultFilter]}}
                },
                buttons: statusFilterButtons
            }}
            query={LogisticsOrdersV2Document}
            queryVariables={{packageStatus}}
            persistFilter={articleFilter.filter}
        />
        <Modal isOpen={modal} toggle={() => toggle(null)}>
            <ModalBody>
                <OrderInvoices invoices={invoices}/>
            </ModalBody>
        </Modal>
    </div>
}
