import React, {memo, useMemo} from "react";
import {
    ArticleRowFragmentFragment,
} from "../generated/graphql";
import {Link} from "react-router-dom";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import CreatePackageModalPanel from "../package/component/CreatePackageModalPanel";


const TABLE_KEY = "TABLE_KEY_ORDER_PACKAGE"

type Props = {
    articleRows: ArticleRowFragmentFragment[]
    orderId: string
}

const OrderPackagePanel = ({articleRows, orderId}: Props) =>
{
    const linkedPackages = useMemo(() =>
    {
        const map = new Map<string, any>()

        articleRows.forEach((ar) =>
        {
            ar.packageItems?.forEach((packageItem) =>
            {
                const p = packageItem.package
                const packageId = p.id
                if (map.has(packageId))
                {
                    map.get(packageId).articleRows.push(ar)
                } else
                {
                    map.set(packageId, ({...p, articleRows: [ar]}))
                }
            })
        })
        return Array.from(map.values())
    }, [articleRows])

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'mark',
                headerName: 'Shipping mark',
                renderCell: (params) =>
                {
                    return <Link to={`/package-detail/${params.id}`}>{params.value}</Link>
                }
            },
            {field: 'type', width: 100},
            {field: 'quantity', type: 'number', width: 100},
            {field: 'weight', type: 'number', width: 100},
            {field: 'length', type: 'number', width: 100},
            {field: 'width', type: 'number', width: 100},
            {field: 'height', type: 'number', width: 100},
            {
                field: 'articleRows',
                headerName: "Article rows in this order",
                width: 300,
                renderCell: (params) =>
                {
                    return <div>
                        {
                            params.value
                                ?.sort((a, b) => a.index - b.index)
                                ?.map((e) =>
                                {
                                    const name = e.name
                                    return <div>{name}</div>
                                })
                        }
                    </div>
                }
            },
        ]
    }, [])

    return <>
        <div style={{display: 'flex'}}>
            <h4 style={{marginRight: '10px'}}>Packages</h4>
            <CreatePackageModalPanel orderId={orderId}
                                     openSearchOrder={true}/>
        </div>

        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: columns,
            disableTooBar: true,
        }} data={linkedPackages}/>
    </>
}

export default memo(OrderPackagePanel)