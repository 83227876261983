import React, {useCallback, useEffect, useState} from 'react';
import {Prompt} from 'react-router';
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col, Modal, ModalBody, ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row, Spinner,
    TabContent, Table,
    TabPane
} from "reactstrap";
import {formatCurrency} from "../UTIL";
import {
    getSpiralStairForm, SpiralStairStepTypes
} from "./definitions";
import Paragraph from "../common/Paragraph";
import SpiralStairExtra from "./SpiralStairExtra";
import SegmentEdit from "./SegmentEdit";
import Trash from "../common/icons/Trash";
import _ from 'lodash';
import BackButtons from "../common/BackButtons";
import Copy from "../common/icons/Copy";
import NewFormRenderer, {prepare} from "../common/NewFormRenderer";
import SpiralStairDetailInfoCard from "./SpiralStairDetailInfoCard";
import GenerateDrawingCard from "./GenerateDrawingCard";
import SpiralstairTimeSpent from "./SpiralstairTimeSpent";
import ArticleRowEditBlock from "./ArticleRowEditBlock";
import {
    ArticleRow, GetSurfaceTreatmentQuery,
    SpiralStair, useArticleRowQuery, useGetSurfaceTreatmentQuery,
    useSpiralStairDetailQuery
} from "../generated/graphql";
import PriceListId from "./PriceListId";
import {useSpiralStairActions} from "./SpiralStairDetailActionsHook";
import SurfaceTreatmentModal from "./SurfaceTreatmentModal";
import {IsAdmin} from "../Auth";
import Collapser from 'common/Collapser';
import {array, mixed, number, object, string} from "yup/es";
import ShortenText from "../common/ShortenText";


const Header = ({articleRowId,spiralStair})=>
{
    const [timeSpentOpen,setTimeSpentOpen] = useState(false);
    const timeSpentToggle = useCallback(()=>setTimeSpentOpen(t=>!t),[setTimeSpentOpen]);
    return <React.Fragment>
        <Row className="justify-content-between">
            <Col sm={2}>
                <BackButtons articleRowId={articleRowId} />
            </Col>
            <Col sm={2}>
                <Paragraph style={{fontSize:"20px",fontWeight:800}}>{spiralStair.artNo}</Paragraph>
            </Col>
            <Col sm={3}>
                <SpiralstairTimeSpent spiralStairId={spiralStair.id} toggle={timeSpentToggle} open={timeSpentOpen}/>
                <ButtonGroup>
                    <Button color="primary" onClick={()=>setTimeSpentOpen(true)}>Time spent</Button>
                </ButtonGroup>
            </Col>
        </Row>
    </React.Fragment>
};

const isEqual = (state,object)=>
{
    for(let key in state)
    {
        if(key !== "__typename"&& state.hasOwnProperty(key) && !_.isEqual(state[key],object[key]))
        {
            return false;
        }
    }
    return true;
};

export default ({match})=>
{
    const {articleRowId,id} = match.params;
    const {data:spiralStairData, loading:spiralStairLoading} = useSpiralStairDetailQuery({
        variables:{ spiralStairId: id }
    });

    let articleRowData, articleRowLoading=false;
    if(articleRowId){
        const res = useArticleRowQuery({
            variables:{ articleRowId: articleRowId }
        });
        articleRowData = res.data;
        articleRowLoading = res.loading
    }

    if(spiralStairLoading || articleRowLoading)
    {
        return <Spinner color={"warning"} />
    }
    else if(spiralStairData)
    {
        if(articleRowData?.articleRow?.prioritisation?.id){
            articleRowData.articleRow["prioritisationId"] = parseInt(articleRowData.articleRow.prioritisation.id);
        }
        return <SpiralStairDetail
            spiralStair={spiralStairData?.spiralStair as SpiralStair}
            articleRow={articleRowData?.articleRow}
        />;
    }
    else
    {
        return null;
    }
}

const priceSchema = object().shape({
    direction: mixed().required(),
    picketType: mixed().required().label("type of railing"),
    stepType: mixed().required(),
    segments: array().of(object().shape({
        height: number().positive().required(),
        platformMaterial: mixed().when(['connectionStep', '$sameStepTypeOnPlatform'], {
            is: (connectionStep, sameStepTypeOnPlatform) =>
            {
                if (connectionStep)
                {
                    return true
                }
                return sameStepTypeOnPlatform
            },
            then: string().nullable(),
            otherwise: mixed().required().label("Platform Type"),
        }),
        platformType: mixed().when('connectionStep', {
            is: false,
            then: mixed().required().label("Type of platform"),
            otherwise: string().nullable()
        }),
        platformS1: number().when('connectionStep', {
            is: false,
            then: number().positive(),
            otherwise: number().nullable()
        }),
        platformS2: number().when('connectionStep', {
            is: false,
            then: number().positive(),
            otherwise: number().nullable()
        })
    }))
})

const PriceSuggestion = ({spiralStair, sugPrice, sugInPrice, currency,errors,unsavedChanges}) =>
{
    const {priceListId} = spiralStair;

    if (errors.length > 0)
    {
        return <>
            <p style={{fontSize: "12px", color: "red"}}>Fix validation errors before calculating price</p>
            <Collapser label={"Show price validation errors"} open={true}>
                <ul className={"validation-list"}>
                    {errors.map((message, index) =>
                        <li key={index} style={{marginBottom: "10px", borderBottom: "2px solid #ddd"}}>
                            <ShortenText text={message} lengthToCollapse={10}/>
                        </li>
                    )}
                </ul>
            </Collapser>
        </>
    }

    if (unsavedChanges)
    {
        return <p style={{color: "red", fontSize: "12px"}}>Save before calculating price</p>
    }

    return <React.Fragment>
        <PriceListId priceListId={priceListId} spiralStairId={spiralStair.id}/>
        <p>Price: {formatCurrency(sugPrice)} <strong>{currency}</strong>
            <div>(For Your Reference)</div>
        </p>
        <p>In price: {formatCurrency(sugInPrice)} <strong>SEK</strong>
            <div>(For Your Reference)</div>
        </p>
    </React.Fragment>;
}

const PriceLogsModal = ({logs, isOpen, toggle}) =>
{
    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Price breakdown</ModalHeader>
        <ModalBody>
            <div style={{maxHeight: "500px", overflow: "auto"}}>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Sales price</th>
                        <th>Value type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        logs.sort((a, b) => a.text.trim().localeCompare(b.text.trim()))
                            .map((e) =>
                            {
                                const {text, value, valueType} = e
                                const valueStyle: any = {textAlign: "right", whiteSpace: "nowrap"}
                                return <tr key={`log-${text}`}>
                                    <td>{text}</td>
                                    <td style={valueStyle}>{value !== null ? formatCurrency(value) : '-'}</td>
                                    <td style={valueStyle}>{value !== null ? formatCurrency(value / 0.6) : '-'}</td>
                                    <td>{valueType ?? '-'}</td>
                                </tr>
                            })
                    }
                    </tbody>
                </Table>
            </div>
        </ModalBody>
    </Modal>
}

const SurfaceTreatmentSubPanel = ({surfaceTreatment}: { surfaceTreatment?: GetSurfaceTreatmentQuery }) =>
{
    const getText = useCallback((isPowderCoating: boolean) =>
    {
        if (!surfaceTreatment)
        {
            return ''
        }
        const list = surfaceTreatment.surfaceTreatmentDetail?.filter((e) => e.id !== "costPerColor") ?? []

        const count = list.filter((e) => isPowderCoating ? e.powdercoating.isChecked : e.galvanizing.isChecked)
            .length

        const text = isPowderCoating ? "powder coated" : "galvanized"

        if (count === 0)
        {
            return `None ${text}.`
        }

        if (count === list.length)
        {
            return `All parts ${text}.`
        }

        return `${count} parts ${text}.`
    }, [surfaceTreatment])

    const textStyle = {fontSize: "18px"}
    return <>
        <Alert color="warning" style={{marginTop: "8px"}}>
            <li style={textStyle}>{getText(false)}</li>
            <li style={textStyle}>{getText(true)}</li>
        </Alert>
    </>
}

export const SpiralStairDetail =  ({spiralStair,articleRow}:{spiralStair:SpiralStair,
    articleRow:Partial<ArticleRow> | null | undefined})=>
{
    const {
        spiralStairState,
        setSpiralStair,
        segments,
        setSegments,
        articleRowModel,
        setArticleRowModel,
        save,
        getPO,
        createSegment,
        deleteSegment,
        copySegment,
        priceSuggestion,
        saveLoading
    } = useSpiralStairActions(spiralStair, articleRow);

    const {data:surfaceTreatment, loading} = useGetSurfaceTreatmentQuery({variables: {spiralStairId: spiralStair.id}, fetchPolicy:"no-cache"} );
    const [tab, setTab] = useState("stair");
    const {sameRailTypeOnPlatform, sameStepTypeOnPlatform} = spiralStairState;
    const unsavedChanges = spiralStairState &&
        (
            !isEqual(spiralStairState, {
                ...prepare(spiralStair, getSpiralStairForm()),
                drawingInformation: spiralStair.drawingInformation,
                revisionTag: spiralStair.revisionTag
            }) ||
            !isEqual(segments, spiralStair.segments) ||
            (articleRow && !isEqual(articleRowModel, articleRow))
        );

    const [surfaceTreatmentModalOpen, setSurfaceTreatmentModalOpen] = useState(false);

    const toggleSurfaceTreatment = useCallback(()=>{
        setSurfaceTreatmentModalOpen(!surfaceTreatmentModalOpen);
    }, [setSurfaceTreatmentModalOpen, surfaceTreatmentModalOpen]);

    const [openLogModal , setOpenLogModal] = useState(false)

    const [priceErrors, setPriceErrors] = useState<string[]>([])

    const toggleOpenLogModal = useCallback(()=>{
        setOpenLogModal(value=> !value)
    }, [])

    const _setPlatformMaterialSame = useCallback(() => {
        setSegments(old => {
            return old.map((oldSegment)=>({...oldSegment, platformMaterial: spiralStairState.stepType}));
        });
    }, [setSegments, spiralStairState.stepType]);

    const _setPlatformPicketTypeSame = useCallback(() => {
        setSegments(old => {
            return old.map((oldSegment)=>({...oldSegment, platformPicketType: spiralStairState.picketType}));
        });
    }, [setSegments, spiralStairState.picketType]);

    const _handleCreateSegment = useCallback(async () => {
        await createSegment();
        if(spiralStairState.sameStepTypeOnPlatform){
            _setPlatformMaterialSame();
        }
        if(spiralStairState.sameRailTypeOnPlatform){
            _setPlatformPicketTypeSame();
        }
    }, [createSegment, spiralStairState.sameStepTypeOnPlatform, spiralStairState.sameRailTypeOnPlatform, _setPlatformMaterialSame, _setPlatformPicketTypeSame]);

    const validatePrice = useCallback(async () =>
    {
        try
        {
            await priceSchema.validate({...spiralStairState, segments},
                {
                    abortEarly: false,
                    context: {sameStepTypeOnPlatform: spiralStairState.sameStepTypeOnPlatform}
                });
            setPriceErrors([]);
        } catch (e: any)
        {
            setPriceErrors(e?.errors ?? []);
        }
    }, [spiralStairState, segments])

    useEffect(() =>
    {
        validatePrice()
    }, [validatePrice]);

    return <>
            <Prompt when={unsavedChanges} message={`You have unchanged changes, are you sure you want to leave?`} />
            <Header spiralStair={spiralStair} articleRowId={articleRow?.id} />
            <Row style={{marginTop:"10px"}}>
                {articleRow && <Col sm={3}>
                    <Card>
                        <CardHeader>
                            <Paragraph style={{fontSize: "18px"}}>Price Calculation</Paragraph>
                        </CardHeader>
                        <CardBody>
                            {articleRow && <ArticleRowEditBlock articleRow={articleRowModel}
                                                                onChange={setArticleRowModel}/>}
                            <PriceSuggestion spiralStair={spiralStair} sugPrice={priceSuggestion.price}
                                             sugInPrice={priceSuggestion.inPrice}
                                             currency={priceSuggestion.currency}
                                             unsavedChanges={unsavedChanges}
                                             errors={priceErrors}/>

                            <IsAdmin><PriceLogsModal isOpen={openLogModal} logs={priceSuggestion.logs}
                                                     toggle={toggleOpenLogModal}/>
                                <Button size="sm" color="secondary" style={{marginBottom: "8px"}}
                                        onClick={toggleOpenLogModal}>View price breakdown</Button>
                            </IsAdmin>
                            <br/>

                            {!saveLoading && <Button size={"sm"} color={"success"} onClick={() => save()}>
                                Save and update price
                            </Button>}
                            {saveLoading && <Spinner color={"warning"}/>}
                            <p>Unsaved changes: {unsavedChanges ?
                                <strong style={{color: "red"}}>Yes</strong> : "No"}</p>
                            {articleRow && spiralStair.purchaseOrder && !unsavedChanges &&
                                <Button size={"sm"} color={"success"} onClick={async () =>
                                {
                                    await getPO();
                                }}>
                                    Update Purchase Order {spiralStair.purchaseOrder.number}
                                </Button>}
                        </CardBody>
                    </Card>
                </Col>}
                <Col sm={3}>
                    <SpiralStairDetailInfoCard spiralStair={spiralStairState} segments={segments}/>
                </Col>
                <Col sm={6}>
                    <GenerateDrawingCard setSpiralStair={setSpiralStair} spiralStairId={spiralStair.id}
                                         unsavedChanges={unsavedChanges} articleRowId={articleRow?.id}
                                         segments={segments}
                                         spiralStair={spiralStairState}/>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            <Nav tabs card>
                                <NavItem>
                                    <NavLink active={tab === 'stair'} onClick={() => {
                                        setTab('stair');
                                    }}>
                                        <h4>Stair</h4>
                                    </NavLink>
                                </NavItem>
                                {segments && segments.map(segment=>
                                {
                                    const key = `segment${segment.id}`;
                                    return <NavItem key={key}>
                                        <NavLink active={tab === key} onClick={() => {
                                            setTab(key);
                                        }}>
                                            <h4>
                                                Section {segment.index + 1}
                                                &nbsp;
                                                <span style={{float:"right", marginLeft: "10px"}} className="link" onClick={e=>deleteSegment(e,segment)}>
                                                        <Trash/>
                                                    </span>
                                                <span style={{float:"right", marginLeft: "4px"}} className="link"
                                                      onClick={e=>copySegment(e,segment)}>
                                                        <Copy/>
                                                    </span>
                                            </h4>
                                        </NavLink>

                                    </NavItem>
                                })}
                                <NavItem>
                                    <Button size="sm" color={"success"} onClick={_handleCreateSegment}>
                                        Add Section
                                    </Button>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={tab}>
                                <TabPane tabId={"stair"}>
                                    <Row>
                                        <Col sm={9}>
                                            {spiralStairState &&
                                            <NewFormRenderer
                                                columns={3}
                                                formDefinition={getSpiralStairForm(unsavedChanges, toggleSurfaceTreatment,  <SurfaceTreatmentSubPanel surfaceTreatment={surfaceTreatment}/>)}
                                                object={spiralStairState}
                                                onChange={ (name,value)=> {
                                                    setSpiralStair(old=>({...old, [name]:value}));

                                                    if(name === "stepType" && spiralStairState.sameStepTypeOnPlatform){
                                                        setSegments(old => {
                                                            return old.map((oldSegment)=>({...oldSegment, platformMaterial: value}));
                                                        });
                                                    }
                                                    if(name === "picketType" && spiralStairState.sameRailTypeOnPlatform){
                                                        setSegments(old => {
                                                            return old.map((oldSegment)=>({...oldSegment, platformPicketType: value}));
                                                        });
                                                    }
                                                    if((name === "sameStepTypeOnPlatform" && value)){
                                                        _setPlatformMaterialSame();
                                                    }
                                                    if(name === "sameRailTypeOnPlatform" && value){
                                                        _setPlatformPicketTypeSame();
                                                    }
                                                }}
                                            />}
                                        </Col>
                                        <Col sm={3}>
                                            <SpiralStairExtra extra={spiralStair.extra} spiralStairId={spiralStair.id} />
                                        </Col>
                                    </Row>
                                </TabPane>
                                {segments && segments.map((segment, index) =>
                                {
                                    const isLastSection = index === segments.length - 1;
                                    const key = `segment${segment.id}`;

                                    return <TabPane key={key} tabId={key}>
                                        <SegmentEdit segment={segment}
                                                     sameRailType={sameRailTypeOnPlatform ? {value: spiralStairState.picketType} : null}
                                                     sameStepType={sameStepTypeOnPlatform ? {value: spiralStairState.stepType} : null}
                                                     direction={spiralStairState.direction}
                                                     onChange={(name, value) =>
                                                     {
                                                         setSegments(old =>
                                                         {
                                                             let index = _.findIndex(old, s => s.id === segment.id);
                                                             const copy = old.slice();
                                                             copy[index] = ({...old[index], [name]: value});
                                                             return copy;
                                                         });
                                                     }}
                                                     minStepHeight={SpiralStairStepTypes.find((e) => e.id === spiralStairState.stepType)?.minStepHeight}
                                                     isLastSection={isLastSection}/>
                                    </TabPane>
                                })}

                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <SurfaceTreatmentModal
                spiralStairId={spiralStair.id}
                prioritisation={articleRow?.prioritisation}
                surfaceTreatmentModalOpen={surfaceTreatmentModalOpen}
                toggle={toggleSurfaceTreatment}
                data={surfaceTreatment}
                loading={loading}
            />
       </>
}
