import {useCallback, useEffect, useMemo, useState} from "react";
import {
    StepType,
    StraightStairSurfaceTreatmentFragmentFragment,
    StraightStairV2FragmentFragment,
    UpdateStraightStairV2InputType,
    useStraightStairV2LazyQuery,
    useUpdateArticleRowMutation,
    useUpdateStraightStairV2Mutation
} from "../../generated/graphql";
import {cloneDeep, isEqual, omit} from "lodash";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {ArticleIdProps, ArticleRowVo, AsnEnabledFunc} from "../StraightStairV2Types";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {generateNewStringer} from "../stringer/StringerGenerator";
import useExtraRailingHook from "./useExtraRailingHook";
import useExtraHook from "./useExtraHook";
import useSeparateKickHook from "./useSeparateKickHook";
import useSegmentHook from "./useSegmentHook";
import useStraightStairPriceHook from "./useStraightStairPriceHook";
import useStairTabHook from "./useStairTabHook";
import useStraightStairValidationHook from "./useStraightStairValidationHook";


export default ({articleRowId, articleId}: ArticleIdProps) =>
{
    const [straightStairV2Old, setStraightStairV2Old] = useState<StraightStairV2FragmentFragment | undefined | null>(null)
    const [articleRowOld, setArticleRowOld] = useState<ArticleRowVo>(null)

    const [straightStairV2, setStraightStairV2] = useState<StraightStairV2FragmentFragment | null>(null)
    const [articleRow, setArticleRow] = useState<ArticleRowVo>(null)

    const {activeTab, updateActiveTab} = useStairTabHook()
    const {validationErrors} = useStraightStairValidationHook({straightStairV2})

    const {
        addExtraRailingLoading, deleteExtraRailingLoading,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
    } = useExtraRailingHook({straightStairV2, setStraightStairV2})

    const {
        addExtraLoading, deleteExtraLoading,
        addExtra, deleteExtra, updateExtra,
    } = useExtraHook({straightStairV2, setStraightStairV2})

    const {
        addSeparateKickPlateLoading, deleteSeparateKickPlateLoading,
        addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
    } = useSeparateKickHook({straightStairV2, setStraightStairV2})

    const {
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading, calculateNumberOfStepsLoading,
        addSegment, deleteSegment, updateSegment, copySegment,
        calculateNumberOfSteps,
        isDoubleRailingAndExtendedEnabled,
        updateSupports
    } = useSegmentHook({straightStairV2, setStraightStairV2, updateActiveTab})

    const {
        priceSuggestion, setPriceSuggestion,
        getPrice, getPriceError
    } = useStraightStairPriceHook({
        articleId,
        articleRowId
    })

    const [getStraightStairV2Query, {loading}] = useStraightStairV2LazyQuery()

    const {
        executeMutation: updateMutation,
        loading: updateLoading
    } = useMutationHandler(useUpdateStraightStairV2Mutation)

    const {
        executeMutation: updateArticleRowMutation,
        loading: updateArticleRowLoading
    } = useMutationHandler(useUpdateArticleRowMutation)

    const isAsnEnabled: AsnEnabledFunc = useCallback((stepType) =>
    {
        return stepType === StepType.StandardGrate || stepType === StepType.TightMeshGrate
    }, [])

    const stringerOptions = useMemo(() =>
    {
        if (straightStairV2 == null || straightStairV2.segments == null)
        {
            return []
        }

        const options = generateNewStringer({...straightStairV2, segments: straightStairV2.segments})
        if (options.length > 0)
        {
            const needStringerMaterial = (straightStairV2.isMinStringerMaterial && options[0] !== straightStairV2.stringerMaterial) ||
                (straightStairV2.stringerMaterial && !options.includes(straightStairV2.stringerMaterial))
            if (needStringerMaterial)
            {
                setStraightStairV2((prevState) =>
                {
                    const stringerMaterial = options[0]
                    prevState?.segments?.filter((e) => e.platformIntegrated)
                        ?.forEach((e) => e.platformFrameMaterial = stringerMaterial)

                    return prevState ? ({...prevState, stringerMaterial: stringerMaterial}) : prevState
                })
            }
        }

        return options
    }, [straightStairV2])

    const updateStraightStair = useCallback((key: string, value) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            let newStair

            if (key === "stairTypeAndRailing")
            {
                newStair = {...prevState, ...value}
                newStair.segments.forEach((e) =>
                {
                    e.platformRailingExecution = value.railingStandard
                    e.platformRailingType = value.railingType
                })
            } else
            {
                newStair = {...prevState, [key]: value}
            }

            if (key === "stepType")
            {
                if (isAsnEnabled(newStair.stepType))
                {
                    newStair.close = false
                } else
                {
                    newStair.asn = false
                }
            }

            if (key === "maxLoad" || key === "preferredMaterial" || key === "stringerMaterial")
            {
                const updateSegments = newStair.segments.filter((e) => e.platformIntegrated)
                updateSegments.forEach((e) =>
                {
                    switch (key)
                    {
                        case "maxLoad":
                            e.platformMaxLoad = value
                            break;
                        case "preferredMaterial":
                            e.platformMaterialType = value
                            break;
                        case "stringerMaterial":
                            e.platformFrameMaterial = value
                            break;
                    }
                })
            }
            return newStair
        })
    }, [setStraightStairV2, isAsnEnabled])

    const updateSurfaceTreatments = useCallback((surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, surfaceTreatments: [...surfaceTreatments]}
        })
    }, [])

    const getStraightStairV2 = useCallback(async () =>
    {
        try
        {
            const response = await getStraightStairV2Query(
                {
                    variables: {
                        articleId: articleId,
                        articleRowId: articleRowId ?? '-1',
                        includeArticleRow: articleRowId != null
                    }
                })
            const straightStairV2 = response.data?.straightStairV2
            if (!straightStairV2)
            {
                return
            }

            setStraightStairV2Old(straightStairV2)
            setStraightStairV2(cloneDeep(straightStairV2))

            const articleRow = response.data?.articleRow
            if (!articleRow)
            {
                return
            }

            const ar = {
                ...articleRow,
                supplierId: articleRow.article?.supplierId,
                weldability: articleRow.article?.weldability
            }
            setArticleRowOld(ar)
            setArticleRow(cloneDeep(ar))
        } catch (e: any)
        {
            NotificationPopup.error(`${e?.message}`)
        }
    }, [articleId, articleRowId, getStraightStairV2Query])

    const updatePOPrice = useCallback(async (newPrice?: number) =>
    {
        const purchaseOrder = straightStairV2?.purchaseOrder
        if (!purchaseOrder)
        {
            return
        }
        const newInPrice = newPrice || priceSuggestion?.inPrice;

        if (!newInPrice)
        {
            return
        }

        const articleRow = purchaseOrder.articleRows?.find((e) => e.article?.id === articleId)
        if (articleRow && articleRow.price !== newInPrice && window.confirm(`This spiral stair is linked to PO ${purchaseOrder.number} with price: ${articleRow.price} ${purchaseOrder.currency},
do you want to update the stair price on the PO to: ${Math.round(newInPrice * 100) / 100} ${purchaseOrder.currency}?`))
        {
            await updateArticleRowMutation({
                variables: {
                    id: articleRow.id,
                    articleRow: {price: newInPrice}
                }
            }, {
                onSuccess: () =>
                {
                    NotificationPopup.success(`Updated Purchase Order`);
                }
            });
        }
    }, [articleId, priceSuggestion, updateArticleRowMutation, straightStairV2?.purchaseOrder]);

    const save = useCallback(async () =>
    {
        if (!straightStairV2 || !articleRow || !articleRowId)
        {
            return
        }

        const data: UpdateStraightStairV2InputType = {
            name: articleRow.name,
            price: articleRow.price,
            supplierId: articleRow.supplierId,
            straightStair: {
                ...omit(straightStairV2, "id", "__typename", "articleId",
                    "surfaceTreatments", "segments", "extraRailings",  "extras",
                    "priceSuggestion", "purchaseOrder", "separateKickPlates"),
                segments: straightStairV2.segments.map((e) =>
                {
                    return {
                        ...omit(e, "__typename", "straightStairV2Id", "geometry","supports"),
                        supports: e.supports.map((s) => omit(s, "__typename")),
                    }
                }),
                extraRailings: straightStairV2.extraRailings.map((e) => omit(e, "__typename")),
                surfaceTreatments: straightStairV2.surfaceTreatments.map((e) => omit(e, "__typename")),
                extras: straightStairV2.extras.map((e) => omit(e, "__typename")),
                separateKickPlates: straightStairV2.separateKickPlates.map((e) => omit(e, "__typename"))
            }
        }

        await updateMutation({
            variables: {
                articleRowId: articleRowId,
                data: data
            },
        }, {
            onSuccess: async () =>
            {
                setPriceSuggestion(null) // 避免顯示錯誤的價錢
                await getStraightStairV2()
                const priceSuggestion = await getPrice()
                if (priceSuggestion)
                {
                    await updatePOPrice(priceSuggestion?.inPrice)
                }
            }
        })
    }, [updateMutation, articleRow, articleRowId, straightStairV2,
        getStraightStairV2, updatePOPrice, getPrice, setPriceSuggestion])

    const hasUnsavedChange = useMemo(() =>
    {
        if (!articleRow || !straightStairV2 || !articleRowOld || !straightStairV2Old)
        {
            return false
        }

        return !isEqual(articleRowOld, articleRow) ||
            !isEqual(straightStairV2, straightStairV2Old)
    }, [articleRowOld, straightStairV2Old, articleRow, straightStairV2])

    const isLoading = useMemo(() =>
    {
        return loading || updateLoading ||
            createSegmentLoading || deleteSegmentLoading || copySegmentLoading ||
            addExtraRailingLoading || deleteExtraRailingLoading ||
            addExtraLoading || deleteExtraLoading ||
            calculateNumberOfStepsLoading || updateArticleRowLoading ||
            addSeparateKickPlateLoading || deleteSeparateKickPlateLoading
    }, [loading, updateLoading,
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading,
        addExtraRailingLoading, deleteExtraRailingLoading,
        addExtraLoading, deleteExtraLoading,
        calculateNumberOfStepsLoading, updateArticleRowLoading,
        addSeparateKickPlateLoading, deleteSeparateKickPlateLoading])

    useEffect(() =>
    {
        getStraightStairV2().then()
    }, [getStraightStairV2]);

    return {
        isLoading, save, hasUnsavedChange,
        straightStairV2, updateStraightStair,
        activeTab, updateActiveTab,
        articleRow, setArticleRow,
        addSegment, deleteSegment, updateSegment, copySegment,
        updateSupports,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
        addExtra, deleteExtra, updateExtra,
        updateSurfaceTreatments,
        calculateNumberOfSteps,
        validationErrors, updatePOPrice, getPriceError,
        priceSuggestion,
        isAsnEnabled, isDoubleRailingAndExtendedEnabled,
        stringerOptions,
        addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
    }
}