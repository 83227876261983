import {PackageFragmentFragment} from "../../../generated/graphql";
import Collapser from "../../../common/Collapser";
import {Table} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";

const PackageTablePanel = ({packages, artNo}: { packages?: PackageFragmentFragment[], artNo?: string | null }) =>
{
    return <>
        <Collapser label={`Show packages (${packages?.length ?? 0})`} open={false}>
            <h5>Packages with {artNo}</h5>
            {packages && packages.length > 0
                ? <Table bordered striped>
                    <thead>
                    <tr>
                        <th>Shipping mark</th>
                        <th>Type</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                        <th>Length</th>
                        <th>Height</th>
                        <th>Width</th>
                    </tr>
                    </thead>
                    <tbody>
                    {packages.map((e, index) =>
                    {
                        const {id, type, mark, quantity, weight, length, height, width} = e
                        return <tr key={`package-row-${index}`}>
                            <td>
                                <Link target="_blank" to={`/package-detail/${id}`}>{mark}</Link>
                            </td>
                            <th>{type}</th>
                            <td style={{textAlign: 'right'}}>{quantity}</td>
                            <td style={{textAlign: 'right'}}>{weight}</td>
                            <td style={{textAlign: 'right'}}>{length}</td>
                            <td style={{textAlign: 'right'}}>{height}</td>
                            <td style={{textAlign: 'right'}}>{width}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                : <p>no package</p>
            }
        </Collapser>
    </>
}

export default PackageTablePanel